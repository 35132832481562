import React from "react";
import {Deviation} from "../dashboard";
import agent from "../../agent";
import DeviationItem from "./DeviationItem";
import {Loader} from "semantic-ui-react";

interface Props {
}

interface State {
    loading: boolean,
    deviations: Deviation[];
}

class Deviations extends React.PureComponent<Props, State> {
    state: State = {
        loading: true,
        deviations: [],
    };

    async componentDidMount() {
        const deviations: Deviation[] = await agent.PatientProvider.getDeviationsList();

        this.setState({deviations: deviations, loading: false});
    };

    render() {
        return (
            this.state.loading ?
            <Loader active/>
            :
            <div style={{
                padding: "12px 48px",
                width: "inherit",
                border: "1px solid darkgrey",
                overflowY: "auto",
                maxHeight: "100vh",
            }}>
                {this.state.deviations.map((deviation)=>{
                    return <DeviationItem deviation={deviation} />
                })}
            </div>
        )
    };
}

export default Deviations;
