import React from "react";
import {Deviation} from "../../dashboard";
import agent from "../../../agent";
import {Loader} from "semantic-ui-react";
import styles from "../index.module.css";
import DeviationItem from "../../deviations/DeviationItem";

interface Props {
    patientId: string;
}

interface State {
    loading: boolean,
    deviation: Deviation | null
}

class DeviationTab extends React.PureComponent<Props, State> {
    state: State = {
        loading: true,
        deviation: null
    };

    async componentDidMount() {
        const deviation: Deviation = await agent.PatientProvider.getDeviationsByPatientId(this.props.patientId);

        this.setState({
            loading: false,
            deviation: deviation
        });
    };

    render() {
        return (
            this.state.loading ?
                <Loader active/>
                :
                <div className={styles.containerInteractionHistory}>
                    {this.state.deviation && <DeviationItem deviation={this.state.deviation} />}
                </div>
        )
    }
}

export default DeviationTab;
