import React from "react";
import {Header, List, Segment} from "semantic-ui-react";
import {Deviation} from "../dashboard";

interface Props {
    deviation: Deviation;
}

interface State {
}

class DeviationItem extends React.PureComponent<Props, State> {

    private getPreparedData = () => {
        let data: {
            date: string,
            items: {
                status: string,
                description: string,
            }[]
        }[] = [];

        this.props.deviation.journalRecords.forEach((journalRecord) => {
            const dateString = new Date(journalRecord.date).toLocaleDateString();

            const idx = data.findIndex((el) => {
                return el.date === dateString;
            });

            let dataItem = {
                status: journalRecord.status,
                description: `${new Date(journalRecord.date).toLocaleTimeString().substring(0, 5)} ${journalRecord.description}`,
            }

            if (idx >= 0) {
                data[idx].items.push(dataItem);
            } else {
                data.push({
                    date: dateString,
                    items: [dataItem]
                });
            }
        });

        data.forEach((dataItem) => {
            dataItem.items = dataItem.items.sort((a, b) => {
                return Number(`${a.description.substr(0, 2)}${a.description.substr(3, 2)}`) - Number(`${b.description.substr(0, 2)}${b.description.substr(3, 2)}`)
            });
        });

        return data.sort((a, b) => {
            const aArr = a.date.split(".");
            const bArr = b.date.split(".");
            const aTimestamp = Date.parse(`${aArr[2]}-${aArr[1]}-${aArr[0]}`);
            const bTimestamp = Date.parse(`${bArr[2]}-${bArr[1]}-${bArr[0]}`);
            return bTimestamp - aTimestamp;
        });
    }

    render() {
        const deviation = this.props.deviation;
        const data = this.getPreparedData();

        return (
            <Segment color={"red"}>
                <Header>
                    <a
                        href={`/dashboard/patients/${deviation.patientId}/edit/patientInfo`}
                        target={'_blank'}
                        rel="noopener noreferrer">
                        {deviation.surname} {deviation.name} {deviation.middlename}
                    </a>
                </Header>

                <List>
                    {data.map((dataItem) => {
                        return <List.Item>
                            <List.Content>
                                <List.Header>{dataItem.date}</List.Header>
                                <List.List>
                                    {dataItem.items.map((item) => {
                                        return <List.Item>
                                            <List.Icon
                                                name={"circle"}
                                                size={"mini"}
                                                color={item.status === "awaiting" ? "yellow" : "red"}
                                                style={{verticalAlign: "middle"}}
                                            />
                                            <List.Content>
                                                <List.Description>{item.description}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    })}
                                </List.List>
                            </List.Content>
                        </List.Item>
                    })}
                </List>
            </Segment>
        )
    }
}

export default DeviationItem;
