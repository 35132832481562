import React, {ChangeEvent, useState} from "react";
import {Button, Dimmer, Form, InputOnChangeData, Loader, Modal} from "semantic-ui-react";
import {TestResult} from "../../../test/TestResultModel";
import {Test} from "../../../test/TestModel";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../../../agent";

interface Props {
    testResult: TestResult;
    onClose: (success: Boolean) => void;
}

const TestResultDeleteModal = (props: Props) => {
    const [saving, setSaving] = useState(false);

    return (
        <Modal
            onClose={() => props.onClose(false)}
            open={!!props.testResult}
            closeIcon>
            <Modal.Header>Удалить анализ?</Modal.Header>
            <Modal.Content>
                <p>
                    Удалить выбранный анализ?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={"Удалить"}
                    disabled={saving}
                    onClick={async () => {
                        setSaving(true);
                        try {
                            await agent.TestResultProvider.remove(props.testResult._id!.toString());
                            props.onClose(true);
                        } finally {
                            setSaving(false);
                        }
                    }}
                    positive
                />
                <Button
                    color={'grey'}
                    disabled={saving}
                    onClick={() => props.onClose(false)}>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>);
}

export default TestResultDeleteModal;