import request, {Response, SuperAgentRequest} from 'superagent';
import {getAddress, getToken} from "./utils/common";
import {ChangePatientDoctorsRequest, Dept, Doctor, Patient} from "./components/dashboard";
import {EventJournalRecord, PatientEvent} from "./components/workTablePatients/EditorTabs/CureProgramTab";
import history from "./history"
import {ConfigPanelOptions} from "./components/configPanel";
import {Test} from "./components/test/TestModel";
import {TestResult, TestResultListOptions} from "./components/test/TestResultModel";

type RequestMethod = "get" | "post" | "put" | "delete";

const sendRequest = (method: RequestMethod, endpoint: string, data: object): SuperAgentRequest => {
    return request[method](getAddress() + endpoint)
        .set('x-access-token', getToken())
        .send(data);
};

const getFileLink = (fileName: string, uploadDate: Date): string => {
    return `/userFile/get/?fileName=${fileName}&uploadDate=${uploadDate}`
};

const getFileLinkById = (fileId: string): string => {
    return `/userFile/get/?id=${fileId}`
};

export const handleResponseErrors = (error: any): any => {
    let message: string | undefined = error.response?.body?.message ?? error.message;
    if (error.message === 'Unauthorized') {
        alert(message);
        history.push('/');
    } else {
        alert(message);
    }
    return error?.response?.body;
};

const getResponseBody = (response: Response) => {
    return response.body;
};

export interface PaginateOptions {
    search?: string,
    page: number,
    limit: number,
    sort?: string,
    doctorId?: string,
    dateStart?: Date,
    dateEnd?: Date,
    isViewed?: boolean,
    type?: string,
    deptId?: string,
    diagnosisFilter?: string,
    isLargeFamilyFilter?: boolean
}

interface ResponseError {
    error: boolean,
    message: string,
}

const emptyPaginateOptions: PaginateOptions = {
    limit: 9999,
    page: 1,
    search: '',
};

const DoctorProvider = {
    create: (doc: Doctor) => sendRequest('post', '/doctor/create', doc)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (doc: Doctor) => sendRequest('post', '/doctor/edit', doc)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getList: (options?: PaginateOptions) => sendRequest('post', '/doctor/getList', options ? options : emptyPaginateOptions)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/doctor/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (docID: string) => sendRequest('post', '/doctor/remove', {id: docID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
};

const DeptProvider = {
    create: (doc: Dept) => sendRequest('post', '/dept/create', doc)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (doc: Dept) => sendRequest('post', '/dept/edit', doc)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getList: (options?: PaginateOptions) => sendRequest('post', '/dept/getList', options ? options : emptyPaginateOptions)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/dept/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (docID: string) => sendRequest('post', '/dept/remove', {id: docID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
};

const UserFileTypeProvider = {
    create: (doc: Dept) => sendRequest('post', '/userFileType/create', doc)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (doc: Dept) => sendRequest('post', '/userFileType/edit', doc)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getList: (options?: PaginateOptions) => sendRequest('post', '/userFileType/getList', options ? options : emptyPaginateOptions)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/userFileType/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (docID: string) => sendRequest('post', '/userFileType/remove', {id: docID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
};

const PatientProvider = {
    create: (patient: Patient) => sendRequest('post', '/patient/create', patient)
        .catch((err) => handleResponseErrors(err)),
    edit: (patient: Patient) => sendRequest('post', '/patient/edit', patient)
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    changePatientsDoctor: (request: ChangePatientDoctorsRequest) =>
        sendRequest('post', '/patient/changePatientsDoctor', request)
            .then(getResponseBody)
            .catch((err) => handleResponseErrors(err)),
    getList: (options: PaginateOptions) => sendRequest('post', '/patient/getList', options)
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    open: (patientID: string) => sendRequest('post', '/patient/getByID', {id: patientID})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    remove: (patientID: string) => sendRequest('post', '/patient/remove', {id: patientID})
        .catch((err) => handleResponseErrors(err)),
    refreshCodeExpiration: (id: string) => sendRequest('post', '/patient/refreshCode', {id: id})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    getInteractionHistory: (patientId: string) => sendRequest('post', '/patient/interactionHistory/getList', {patientId: patientId})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    editInteractionHistory: (id: string, comment: string) => sendRequest('post', '/patient/interactionHistory/edit', {
        id: id,
        comment: comment
    })
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    createInteractionHistory: (patientId: string) => sendRequest('post', '/patient/interactionHistory/create', {patientId: patientId})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    getDeviationsByPatientId: (patientId: string) => sendRequest('post', '/patient/deviations/getByPatientId', {id: patientId})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    getDeviationsList: () => sendRequest('post', '/patient/deviations/getList', {})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
};

const FileProvider = {
    getList: (id: string) => sendRequest('post', '/userFile/getList', {id: id})
        .then(getResponseBody)
        .catch((err) => handleResponseErrors(err)),
    getFile: (name: string, uploadDate: Date) => sendRequest('get', `${getFileLink(name, uploadDate)}`, {})
        .responseType('arraybuffer')
        .then((res: Response) => {
            return res;
        })
        .catch((err) => handleResponseErrors(err)),
    getFileById: (id: string) => sendRequest('get', `${getFileLinkById(id)}`, {})
        .responseType('arraybuffer')
        .then((res: Response) => {
            return res;
        })
        .catch((err) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/userFile/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    setType: (id: string, typeId: string) => sendRequest('put',
        '/userFile/setType/' + id.toString() + "?type=" + typeId, {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    parse: (id: string) => sendRequest('get',
        '/userFile/parse/' + id.toString(), {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    upload: (file: File, userFileType: string, patientId: string) =>
        request['post'](getAddress() + `/userFile/upload`)
            .set('x-access-token', getToken())
            .field("userFileType", userFileType)
            .field("patientId", patientId)
            .attach("file", file)
            .responseType('arraybuffer')
            .then((res: Response) => {
                return res;
            })
            .catch((err) => handleResponseErrors(err)),
    remove: (id: string) => sendRequest('delete',
        '/userFile/' + id.toString(), {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),

};

const AuthProvider = {
    login: (login: string, password: string) => sendRequest('post', '/auth/login', {
        login: login,
        password: password
    }),
    telegramLogin: (data: TelegramAuthResponse) => sendRequest('post', '/auth/telegramLogin', data),
    changeAdminPassword: (oldPassword: string, newPassword: string) =>
        sendRequest('post', '/auth/changeAdminPassword', {
        oldPassword: oldPassword,
        newPassword: newPassword
    }).catch((err: ResponseError) => handleResponseErrors(err)),
};

const PatientEventProvider = {
    getList: (patientID: string, eventType: string | undefined) => sendRequest('post',
        '/patientEvent/getList' + (eventType !== undefined ? "?eventType=" + eventType : ""),
        {id: patientID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    create: (patientEvent: {
        repeatDays: string[]; patientId: string; endDate: Date; repeatType: string; description: string;
        eventType: string; cancellationTimeframeTo: Date | null; finishType: string;
        repeatByWeeksCount: number;
        repeatByMonthsCount: number;
        repeatByDaysCount: number; journal: EventJournalRecord[]; repeatSeveralTimesCount: number; _id: string; cancellationTimeframeFrom: Date | null; startDate: Date | undefined
    }) => sendRequest('post', '/patientEvent/create', patientEvent)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (patientID: string) => sendRequest('post', '/patientEvent/remove', {id: patientID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (patientEvent: PatientEvent) => sendRequest('post', '/patientEvent/edit', patientEvent)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (patientEventID: string) => sendRequest('post', '/patientEvent/getByID', {id: patientEventID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getHospitalizations: (deptId?: String, doctorId?: String) =>
        sendRequest('post',
            '/patientEvent/hospitalizations?t=1' +
            (deptId ? ("&deptId=" + deptId) : "") +
            (doctorId ? ("&doctorId=" + doctorId) : "")
            , {})
            .then(getResponseBody)
            .catch((err: ResponseError) => handleResponseErrors(err)),
};

const PatientActivityProvider = {
    getList: (options: PaginateOptions) => sendRequest('post', '/patientActivity/getList', options)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),

    markAsViewed: (id: string, isViewed: boolean) => sendRequest('post',
        (isViewed ?
            '/patientActivity/view/' : '/patientActivity/unview/') + id.toString(), {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err))
};


const ConfigProvider = {
    get: () => sendRequest('post', '/config/get', {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (config: ConfigPanelOptions) => sendRequest('post', '/config/edit', config)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    checkTransport: () => sendRequest('post', '/config/checkTransport', {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
};

const TestProvider = {
    create: (doc: Test) => sendRequest('post', '/test/create', doc)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (doc: Test) => sendRequest('post', '/test/edit', doc)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getList: (options?: PaginateOptions) => sendRequest('post', '/test/getList', options ? options : emptyPaginateOptions)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/test/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (docID: string) => sendRequest('post', '/test/remove', {id: docID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
};

const TestResultProvider = {
    create: (doc: TestResult) => sendRequest('post', '/testResult/create', doc)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    edit: (doc: TestResult) => sendRequest('post', '/testResult/edit', doc)
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    getList: (options?: TestResultListOptions) => sendRequest('post', '/testResult/getList', options ? options : {})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    open: (docID: string) => sendRequest('post', '/testResult/getByID', {id: docID})
        .then(getResponseBody)
        .catch((err: ResponseError) => handleResponseErrors(err)),
    remove: (docID: string) => sendRequest('post', '/testResult/remove', {id: docID})
        .catch((err: ResponseError) => handleResponseErrors(err)),
};


export default {
    DoctorProvider,
    PatientProvider,
    AuthProvider,
    FileProvider,
    PatientEventProvider,
    ConfigProvider,
    PatientActivityProvider,
    DeptProvider,
    TestProvider,
    TestResultProvider,
    UserFileTypeProvider
};