import React, {useEffect, useState} from "react";
import {getToken} from "../../utils/common";
import {Login} from "../login";
import Dashboard from "../dashboard";
import history from "../../history"

export function Auth(): JSX.Element {
    const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (getToken()) {
            setAuthenticated(true);
        } else {
            history.push('/')
        }
    }, []);

    if (authenticated === undefined) {
        return <></>
    } else if (authenticated) {
        return (
            <Dashboard history={history}/>
        )
    } else {
        return (
            <Login/>
        )
    }
}
