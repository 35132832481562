import React, {ChangeEvent, FormEvent} from "react";
import styles from "./index.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Dept, Doctor, PatientsActivityListItem} from "../dashboard";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Icon,
    Input,
    Loader,
    Menu,
    Table
} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {DoctorPaginationResponse} from "../workTableDoctors/DoctorsList";
import {
    DoctorTitleCase,
    getDoctorId,
    getDoctorTitle,
    getPatientTitle,
    getTZ,
    PatientTitleCase
} from "../../utils/common";
import request from "superagent";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import {Utils} from "../../helpers/Utils";
import MediaQuery from "react-responsive";
import moment from "moment";

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    items: PatientsActivityListItem[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
    doctorid: string,
    doctors: Doctor[],
    deptId?: string,
    depts: Dept[],
    doctorDisabled: boolean,
    dateStart?: Date,
    dateEnd?: Date,
    isViewed?: boolean,
    type?: string
}

export interface PatientsActivityListItemPaginationResponse {
    docs: PatientsActivityListItem[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class PatientActivityList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        items: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: 'time',
        sortOrder: 'descending',
        searchValue: '',
        doctorid: "",
        doctors: [],
        depts: [],
        doctorDisabled: false
    };

    async componentDidMount() {
        await this.getDoctorList();
        await this.getPatientsActivityListItemsList();
        let doctorid = await getDoctorId();
        if (doctorid)
            this.setState({doctorid: doctorid, doctorDisabled: true});
    };

    getDoctorList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "surname"
        };

        const responseDoctors: DoctorPaginationResponse = await agent.DoctorProvider.getList(paginateOptions);
        const responseDepts: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (responseDoctors && responseDepts) {
            this.setState({
                doctors: responseDoctors.docs,
                depts: responseDepts.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getPatientsActivityListItemsList = async (goToPage?: number, searchString?: string,
                                              doctorid?: string, dateStart?: Date, dateEnd?: Date,
                                              isViewed?: boolean, type?: string): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        let sort = this.state.sortColumn
        ;
        if (sort && this.state.sortOrder === "descending")
            sort = "-" + sort;

        const paginateOptions: PaginateOptions = {
            limit: 10,
            page: goToPage ? goToPage : 1,
            sort: sort,
            search: searchTo,
            doctorId: doctorid,
            dateStart: dateStart,
            dateEnd: dateEnd,
            isViewed: isViewed,
            type: type,
            deptId: this.state.deptId
        };
        if (paginateOptions.sort == null)
            paginateOptions.sort = this.state.sortColumn;

        const response: PatientsActivityListItemPaginationResponse = await agent.PatientActivityProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                items: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    timeout: NodeJS.Timeout | undefined;

    PatientsActivityListItemsSearch = (): void => {
        this.setState({loading: true});
        this.getPatientsActivityListItemsList(1, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        //let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            //  sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }
        this.setState({sortColumn: columnName});

        this.getPatientsActivityListItemsList(this.state.page, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type);
    };

    options = [
        {key: false, text: 'Не просмотренные', value: false},
        {key: true, text: 'Просмотренные', value: true}
    ]

    optionsType = [
        {key: 1, text: 'Файл', value: "FILE"},
        {key: 2, text: `Связь с ${getDoctorTitle(DoctorTitleCase.om)}`, value: "EMERGENCY"},
        {key: 3, text: 'Пропуск (5 дн)', value: "DECLINED_EVENT"},
        {key: 4, text: 'Неактивный (10 дн)', value: "INACTIVE_PATIENT"},
        {key: 5, text: 'Без назначений (2 дн)', value: "EMPTY_EVENTS_PATIENT"},
        {
            key: 6,
            text: `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`,
            value: (Utils.vomode() ? "REMINDER_VO" : "REMINDER")
        }
    ]

    render() {
        const headerLabel: string = `Активность ${getPatientTitle(PatientTitleCase.ov)}`;

        return (
            <div className={styles.table}>

                <div className={styles.header} style={{marginBottom: '24px'}}>
                    <h3>
                        {headerLabel}
                    </h3>
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Field width={"3"}>
                                        <label>
                                            <p>ФИО {getPatientTitle(PatientTitleCase.a)}</p>
                                        </label>
                                        <Input

                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.searchValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({searchValue: e.target.value})
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            <p>{getDoctorTitle(DoctorTitleCase.im)}</p>
                                        </label>
                                        <Dropdown
                                            disabled={this.state.doctorDisabled}
                                            selection
                                            clearable={true}
                                            value={this.state.doctorid}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({doctorid: data.value != null ? data.value.toString() : ""},
                                                    this.PatientsActivityListItemsSearch)
                                            }}
                                            options={this.state.doctors.map((doctor) => ({
                                                key: doctor._id,
                                                text: doctor.surname + " " + doctor.name[0] + "." + doctor.middlename[0] + ".",
                                                value: doctor._id,
                                            } as DropdownItemProps))}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            {Utils.vomode() ? 'Район' : 'Отделение'}
                                        </label>
                                        <Dropdown
                                            disabled={this.state.doctorDisabled}
                                            selection
                                            clearable={true}
                                            value={this.state.deptId}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({deptId: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.PatientsActivityListItemsSearch)
                                            }}
                                            options={this.state.depts.map((dept) => ({
                                                key: dept._id,
                                                text: dept.name,
                                                value: dept._id,
                                            } as DropdownItemProps))}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field>
                                        <label>
                                            Фильтр Просмотрено
                                        </label>
                                        <Dropdown clearable options={this.options} selection
                                                  value={this.state.isViewed}
                                                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                      let isViewed: boolean | undefined;
                                                      isViewed = data.value === true;
                                                      if (data.value === "")
                                                          isViewed = undefined;
                                                      this.setState({isViewed: isViewed},
                                                          this.PatientsActivityListItemsSearch)
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Тип
                                        </label>
                                        <Dropdown clearable options={this.optionsType} selection
                                                  value={this.state.type}
                                                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                      let type: string | undefined;
                                                      if (data.value)
                                                          type = data.value.toString();
                                                      this.setState({type: type},
                                                          this.PatientsActivityListItemsSearch)
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={2}>
                                        <label>
                                            Дата начала
                                        </label>
                                        <DatePicker
                                            locale={"ru"}
                                            dateFormat={"dd.MM.yyyy"}
                                            todayButton={true}
                                            selected={this.state.dateStart} onChange={(v: Date) => {
                                            this.setState({
                                                dateStart: v
                                            }, this.PatientsActivityListItemsSearch)
                                        }}/>
                                    </Form.Field>
                                    <Form.Field width={2}>
                                        <label>
                                            Дата окончания
                                        </label>
                                        <DatePicker selected={this.state.dateEnd}
                                                    locale={"ru"}
                                                    dateFormat={"dd.MM.yyyy"}
                                                    onChange={(v: Date) => {
                                                        this.setState({
                                                            dateEnd: v
                                                        }, this.PatientsActivityListItemsSearch)
                                                    }}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Button primary={true} content='Поиск' onClick={() => {
                                        this.PatientsActivityListItemsSearch()
                                    }}/>
                                    <Button basic color='orange' content='Сбросить'
                                            onClick={() => {
                                                this.setState({
                                                    searchValue: "",
                                                    doctorid: "",
                                                    dateStart: undefined,
                                                    dateEnd: undefined,
                                                    isViewed: undefined,
                                                    type: undefined,
                                                    sortColumn: "time"
                                                }, this.PatientsActivityListItemsSearch)
                                            }}/>
                                    <Form.Field>
                                        <label>
                                            Быстрый выбор
                                        </label>
                                        <Button.Group>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    this.setState({
                                                        dateStart: new Date(),
                                                        dateEnd: new Date()
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За сегодня</Button>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    let date: Date = new Date();
                                                    date.setDate(date.getDate() - 1);
                                                    this.setState({
                                                        dateStart: date,
                                                        dateEnd: date
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За вчера</Button>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    let date = this.getMonday(new Date());
                                                    this.setState({
                                                        dateStart: date,
                                                        dateEnd: new Date()
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За эту неделю</Button>
                                        </Button.Group>
                                    </Form.Field>
                                </Form.Group>

                            </Form>

                            <div className={styles.container}>
                                <Table unstackable selectable sortable striped celled verticalAlign='middle'
                                       color={"teal"} compact={"very"}>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell>Просм.</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'time' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('time')}
                                            >Время</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'surname' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('surname')}>{Utils.vomode() ? 'Гражданин' : 'Пациент'}</Table.HeaderCell>
                                            <MediaQuery minWidth={1224}>
                                                <Table.HeaderCell
                                                >{getDoctorTitle(DoctorTitleCase.im)}</Table.HeaderCell>
                                                <Table.HeaderCell

                                                    sorted={this.state.sortColumn === 'type' ? this.state.sortOrder : undefined}
                                                    onClick={() => this.sortColumn('type')}
                                                >Тип</Table.HeaderCell>
                                                <Table.HeaderCell
                                                    sorted={this.state.sortColumn === 'notificationStatus' ? this.state.sortOrder : undefined}
                                                    onClick={() => this.sortColumn('notificationStatus')}>Статус<br/>
                                                    отправки<br/>email</Table.HeaderCell>
                                            </MediaQuery>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.items && this.state.items.map((el, index) =>
                                                (
                                                    <Table.Row style={{
                                                        cursor: "pointer"

                                                    }}
                                                               key={`key-${index}-${el._id}`}>
                                                        <Table.Cell textAlign={"center"} style={{
                                                            width: "10px"

                                                        }}>
                                                            <Checkbox checked={el.isViewed}
                                                                      onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                                          this.markAsViewed(el, data.checked === true)
                                                                      }}/>
                                                        </Table.Cell>

                                                        <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                            <div>
                                                                {moment(el.time).tz(getTZ()).format("DD.MM.YY")}
                                                                <br/>
                                                                {moment(el.time).tz(getTZ()).format("HH:mm:ss")}
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Link
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                to={`patients/${el.patientId}/edit/patientInfo`}>
                                                                {`${el.patientName}`}
                                                            </Link>

                                                        </Table.Cell>
                                                        <MediaQuery minWidth={1224}>
                                                            <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                                <div>
                                                                    {`${el.doctorName}`}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell floated={'left'}>
                                                                <div>
                                                                    {
                                                                        el.type === "FILE" ?
                                                                            <Link
                                                                                onClick={(e) => PatientActivityList.onFileClick(e,
                                                                                    el.fileName!, el.fileUploadDate!, el.fileContentType!)}
                                                                                to={""}>
                                                                                {PatientActivityList.getTypeDisplayName(el.type)}
                                                                            </Link>
                                                                            :
                                                                            `${PatientActivityList.getTypeDisplayName(el.type)}`}
                                                                    {(el.notificationMessage != null) ? `: ${el.notificationMessage}` : null}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                                <div>
                                                                    {`${PatientActivityList.getNotificationStatusDisplayName(el.notificationStatus)}`}
                                                                </div>
                                                            </Table.Cell>
                                                        </MediaQuery>

                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='6'>
                                                <Menu floated='right' pagination>
                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.gotoPage(1)}
                                                    >
                                                        <Icon name='angle double left'/>
                                                    </Menu.Item>

                                                    {
                                                        this.state.prevPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.gotoPage(this.state.prevPage)}
                                                            >
                                                                {this.state.prevPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        style={{background: 'lightgray'}}
                                                    >
                                                        {this.state.page}
                                                    </Menu.Item>

                                                    {
                                                        this.state.nextPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.gotoPage(this.state.nextPage)}
                                                            >
                                                                {this.state.nextPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.gotoPage(this.state.totalPages)}
                                                    >
                                                        <Icon name='angle double right'/>
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                        {`Страниц: ${this.state.totalPages !== undefined ? this.state.totalPages : 0}`}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {`Записей: ${this.state.totalDocs}`}
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }

    private static async onFileClick(e: React.MouseEvent<HTMLAnchorElement>,
                                     fileName: string,
                                     fileUploadDate: Date,
                                     fileContentType: string) {
        e.preventDefault();

        const data: request.Response | void = await agent.FileProvider.getFile(fileName!, fileUploadDate);

        if (data) {
            const newFile: Blob = new Blob([data.body], {type: fileContentType})
            const newFileURL: string = URL.createObjectURL(newFile);

            if (fileContentType.includes('image')) {
                window.open(newFileURL, '_blank');
            } else {
                let a: HTMLAnchorElement = document.createElement('a');

                a.style.display = 'none';
                document.body.appendChild(a);
                a.href = newFileURL;
                a.download = fileName;

                a.click();
            }

            URL.revokeObjectURL(newFileURL);
        }
    }

    private static getTypeDisplayName(type: string) {
        if (type === "EMERGENCY")
            return `Запрос связи с ${getDoctorTitle(DoctorTitleCase.om)}`;
        else if (type === "FILE")
            return "Загружен файл";
        else if (type === "DECLINED_EVENT")
            return "Пропуск (5 дн)";
        else if (type === "INACTIVE_PATIENT")
            return "Неактивный (10 дн)";
        else if (type === "EMPTY_EVENTS_PATIENT")
            return "Без назначений (2 дн)";
        else if (type === "REMINDER")
            return `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`;
        else
            return "?";
    }

    private static getNotificationStatusDisplayName(type: string) {
        if (type === "PENDING")
            return "Ожидание отправки";
        else if (type === "PENDING")
            return "Отправляется";
        else if (type === "SENT")
            return "Отправлено";
        else if (type === "ERROR")
            return "Ошибка";
        else if (type === "NONE")
            return "Нет";
        else
            return "?";
    }

    private async markAsViewed(el: PatientsActivityListItem, isViewed: boolean) {
        el.isViewed = isViewed
        await agent.PatientActivityProvider.markAsViewed(el._id, isViewed)
        this.forceUpdate();

    }

    private getMonday(d: Date): Date {
        let day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    private async gotoPage(page: number) {
        await this.getPatientsActivityListItemsList(page, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type);
    }
}

export default PatientActivityList;
