import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button, Container,
    Dimmer,
    DropdownItemProps,
    DropdownProps,
    Form, Grid, GridColumn, Icon,
    InputOnChangeData,
    Loader,
    Modal, Segment, Tab, TextArea, TextAreaProps
} from "semantic-ui-react";
import {TestResult} from "../../../test/TestResultModel";
import {Test} from "../../../test/TestModel";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../../../agent";
import {PatientFile, UserFileType} from "../../../dashboard";
import {Utils} from "../../../../helpers/Utils";
import UserFileTypeList from "../../../workTableUserFileTypes/UserFileTypeList";
import TestResultScanModal from "./TestResultScanModal";
import {getRoleEnableScan, getTZ} from "../../../../utils/common";
import moment from "moment";

interface Props {
    test: Test,
    testResult: TestResult;
    patientId: string,
    onClose: () => void;
}

const TestResultEditModal = (props: Props) => {
    const [name, setName] = useState(props.testResult.name);
    const [comment, setComment] = useState(props.testResult.comment);
    const [date, setDate] = useState(props.testResult.date);
    const [saving, setSaving] = useState(false);
    const [fileId, setFileId] = useState<string | undefined>(props.testResult?.fileId?.toString());
    const [files, setFiles] = useState<PatientFile[]>([]);
    const [userFileTypes, setUserFileTypes] = useState<UserFileType[]>([]);
    const [scanModalVisible, setScanModalVisible] = useState(false);
    const [text, setText] = useState<String | undefined>(props.testResult.text);

    useEffect(() => {
        agent.FileProvider.getList(props.patientId).then((x) => {
            var list = x.sort((a: PatientFile, b: PatientFile) => {
                return (a.uploadDate < b.uploadDate) ? 1 : ((b.uploadDate < a.uploadDate) ? -1 : 0);
            });

            setFiles(list);
        })
        agent.UserFileTypeProvider.getList().then((x) => {
            setUserFileTypes(x.docs);
        })
    }, []);

    function getUserFileTypeName(fileTypeId: string | undefined) {
        if (fileTypeId) {
            return userFileTypes!.find((x) => x._id === fileTypeId)?.name
        }
        return undefined;
    }

    function onScanClicked() {
        props.testResult.text = text;
        setScanModalVisible(true);
    }

    const panes = [
        {
            menuItem: 'Анализ', render: () => <Tab.Pane>
                <div>
                    <Form>
                        <Form.Input label={"Тип анализа"}
                                    readonly={true} value={props.test.name}>
                        </Form.Input>
                        <SemanticDatepicker value={date}
                                            disabled={saving}
                                            locale="ru-RU"
                                            label={"Дата *"}
                                            format={"DD.MM.YYYY"}
                                            placeholder={"Выберите дату"}
                                            onChange={(event, data) =>
                                                setDate(data.value as Date)}/>

                        <Form.Group>
                            {fileId && <div style={{marginTop: "26px"}}>
                                <Button size="mini" icon onClick={async () => {
                                    await Utils.openFile({fileId: fileId});
                                }
                                }>
                                    <Icon name="file outline"/>
                                </Button>
                            </div>}
                            <Form.Dropdown width={16}
                                           value={fileId}
                                           label={"Файл"}
                                           selection
                                           clearable
                                           onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                               setFileId(data.value as string);
                                           }}
                                           options={files.map((file) => ({
                                               key: file._id,
                                               text: file.filename,
                                               value: file._id,
                                               content:
                                                   <Grid>
                                                       <Grid.Column width={7}>{file.filename}</Grid.Column>
                                                       <Grid.Column
                                                           width={5}>{getUserFileTypeName(file.metadata?.fileTypeId)}</Grid.Column>
                                                       <Grid.Column
                                                           width={4}>{file?.uploadDate ?
                                                           moment(new Date(file?.uploadDate)).tz(getTZ()).format("DD.MM.YY HH:mm:ss") : undefined}</Grid.Column>
                                                   </Grid>
                                           } as DropdownItemProps))}
                            >
                            </Form.Dropdown>
                            {/*<Icon name={"add"}/>*/}
                        </Form.Group>
                        <Form.Input
                            label={'Наименование (опционально)'}
                            value={name}
                            disabled={saving}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                setName(e.target.value);
                            }
                            }/>
                        <Form.Input
                            label={'Комментарий (опционально)'}
                            value={comment}
                            disabled={saving}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                setComment(e.target.value);
                            }
                            }/>
                        <Dimmer active={saving} inverted>
                            <Loader inverted content='Сохранение'/>
                        </Dimmer>

                    </Form>
                </div>
            </Tab.Pane>
        },
        {
            menuItem: 'Текст', render: () => <Tab.Pane>
                <Form> <TextArea value={text?.toString()} rows={18}
                                 onChange={(e: ChangeEvent<TextAreaProps>) => {
                                     setText(e.target.value?.toString() ?? undefined)
                                 }}
                /></Form>
            </Tab.Pane>
        }
    ]


    return (
        <div>
            {scanModalVisible && fileId &&
            <TestResultScanModal fileId={fileId} testResult={props.testResult}
                                 onClose={(success) => {
                                     setScanModalVisible(false);
                                     if (success)
                                         setText(props.testResult.text);
                                 }}/>}
            <Modal
                onClose={props.onClose}
                open={!!props.testResult}
                closeIcon>
                <Modal.Header>Анализ</Modal.Header>
                <Modal.Content>
                    <Tab panes={panes}/>
                </Modal.Content>
                <Modal.Actions>
                    {getRoleEnableScan() &&
                    window.config?.moniki == true &&
                    <Button
                        color={'orange'}
                        disabled={saving || !fileId}
                        onClick={onScanClicked}>
                        Распознать
                    </Button>}
                    <Button
                        content={"Сохранить"}
                        disabled={saving}
                        onClick={async () => {
                            setSaving(true);
                            try {
                                if (!props.testResult._id) {
                                    await agent.TestResultProvider.create({
                                        date: date!,
                                        name: name,
                                        comment: comment,
                                        testId: props.test._id,
                                        patientId: props.patientId,
                                        fileId: fileId,
                                        values: props.testResult.values,
                                        text: text
                                    });
                                } else {
                                    await agent.TestResultProvider.edit({
                                        _id: props.testResult._id,
                                        date: date!,
                                        name: name,
                                        fileId: fileId,
                                        comment: comment,
                                        values: props.testResult.values,
                                        text: text
                                    });
                                }
                                props.onClose();
                            } finally {
                                setSaving(false);
                            }
                        }}
                        positive
                    />
                    <Button
                        color={'grey'}
                        disabled={saving}
                        onClick={props.onClose}>
                        Отмена
                    </Button>
                </Modal.Actions>
            </Modal></div>);
}

export default TestResultEditModal;