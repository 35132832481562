import React, {useState} from "react";
import {Button, Modal} from "semantic-ui-react";
import {PatientFile} from "../dashboard";
import agent from "../../agent";

interface Props {
    files: PatientFile[];
    onClose: (success: Boolean) => void;
}

const UserFileDeleteModal = (props: Props) => {
    const [saving, setSaving] = useState(false);

    return (
        <Modal
            onClose={() => props.onClose(false)}
            open={!!props.files}
            closeIcon>
            <Modal.Header>Удалить файл?</Modal.Header>
            <Modal.Content>
                <p>
                    {`Вы действительно хотите удалить ${props.files.length} отмеченных файлов?`}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={"Удалить"}
                    disabled={saving}
                    onClick={async () => {
                        setSaving(true);
                        try {
                            for (const file of props.files) {
                                await agent.FileProvider.remove(file._id!.toString());
                            }
                            props.onClose(true);
                        } finally {
                            setSaving(false);
                        }
                    }}
                    positive
                />
                <Button
                    color={'grey'}
                    disabled={saving}
                    onClick={() => props.onClose(false)}>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>);
}

export default UserFileDeleteModal;