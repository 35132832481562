import {getTZ, NullDate} from "../utils/common";
import moment from "moment";

import {
    CalendarEvent,
    DaysOfWeek,
    EventJournalRecord,
    PatientEvent,
    RepeatTypes,
    EventTypes
} from "../components/workTablePatients/EditorTabs/CureProgramTab";

const JournalStatusType = {
    Awaiting: 'awaiting',
    Confirmed: 'confirmed',
    Declined: 'declined',
    Missed: 'missed',
    ScheduledDeclined: 'scheduled_declined',
}

const convertDateToCalendarDate = (date: Date): string => {
    let result = moment(date).tz(getTZ()).format("YYYY-MM-DDTHH:mm");
    console.log('convertDateToCalendarDate: ' + date + ' = ' + result);
    return result;
    //return `${date.getFullYear()}-${convertNumberLengthToLengthOfTwo(date.getMonth() + 1)}-${convertNumberLengthToLengthOfTwo(date.getDate())}T${convertNumberLengthToLengthOfTwo(date.getHours())}:${convertNumberLengthToLengthOfTwo(date.getMinutes())}`;
};

//create array of dates for show in calendar
export const EventRepeater = (patientEvent: PatientEvent, specificTitle?: string, specificUrl?: string): CalendarEvent[] => {
    const hasJournal: boolean = patientEvent.journal && patientEvent.journal.length > 0;

    let eventArray: CalendarEvent[] = [];
    const eventStartDate = new Date(patientEvent.startDate.toString());
    const eventEndDate = new Date(patientEvent.endDate.toString());
    const now = new Date();
    let lastDate: Date = new Date(eventStartDate.getTime());

    //add journal events to all events array and find latest journal record
    if (hasJournal) {
        let latestJournalDate: Date = patientEvent.journal[0].date;

        patientEvent.journal.forEach((record: EventJournalRecord) => {
            // не показываем события, которые были случайно созданы после даты окончания (был баг при разделении событий)
            if (!(patientEvent.endDate != null && record.date > patientEvent.endDate)) {
                eventArray.push(convertJournalRecordToCalendarEvent(record, patientEvent._id));

                if (latestJournalDate < record.date) {
                    latestJournalDate = record.date
                }
            }
        })

        lastDate = new Date(latestJournalDate);
    }

    //calculate start, end dates and convert them to calendar format
    // const eventStartDate: string = convertDateToCalendarDate(eventStartDateDate);
    // const eventEndDate: string = (patientEvent.endDate || patientEvent.endDate !== NullDate) ? convertDateToCalendarDate(new Date(patientEvent.endDate.toString())) : '';

    switch (patientEvent.repeatType) {
        case RepeatTypes.DAILY: {
            //fill calendar daily event dates array by end date
            for (let i = 0; i <= getCountOfRepeatDaysByEndDate(eventStartDate, eventEndDate, patientEvent.repeatByDaysCount); i++) {
                const eventDate: Date = increaseDateByDayCount(eventStartDate, patientEvent.repeatByDaysCount * i);

                // condition to separate showing events with journal record and with not sent or awaiting to send notification
                if (hasJournal ? (now < eventDate) : (lastDate <= eventDate)) {
                    eventArray.push({
                        title: patientEvent.description,
                        start: convertDateToCalendarDate(eventDate),
                        groupId: patientEvent._id,
                        backgroundColor: getFutureEventBackgroundColor(patientEvent, eventDate),
                    })
                }
            }

            break
        }

        case RepeatTypes.WEEKLY: {
            patientEvent.repeatDays.forEach((value => {
                //get date of event start day of week
                const eventStartAt: Date = getNextDayOfEvent(eventStartDate, value, patientEvent.repeatByWeeksCount);

                //fill calendar weekly event dates array by end date
                for (let i = 0; i <= getCountOfRepeatWeeksByEndDate(eventStartAt, eventEndDate, patientEvent.repeatByWeeksCount); i++) {
                    const eventDate: Date = increaseDateByDayCount(eventStartAt, patientEvent.repeatByWeeksCount * 7 * i);

                    //condition to separate showing events with journal record and with not sent or awaiting to send notification
                    if (hasJournal ? (now < eventDate) : (lastDate <= eventDate)) {
                        eventArray.push({
                            title: patientEvent.description,
                            //get next event date depend on week repeat count
                            start: convertDateToCalendarDate(eventDate),
                            groupId: patientEvent._id,
                            backgroundColor: getFutureEventBackgroundColor(patientEvent, eventDate),
                        })
                    }
                }
            }))

            break
        }

        case RepeatTypes.NO_REPEATABLE: {
            //set only one event date with condition to separate showing events with journal record and with not sent or awaiting to send notification
            if (hasJournal ? (now < eventStartDate) : (lastDate <= eventStartDate)) {
                eventArray.push({
                    title: patientEvent.description,
                    start: convertDateToCalendarDate(eventStartDate),
                    groupId: patientEvent._id,
                    backgroundColor: getFutureEventBackgroundColor(patientEvent, eventStartDate),
                });
            }

            break
        }

        case RepeatTypes.MONTHLY: {
            for (let i = 0; i <= getCountOfRepeatMonthsByEndDate(eventStartDate, eventEndDate, patientEvent.repeatByMonthsCount); i++) {
                const eventDate: Date = increaseDateByMonthCount(eventStartDate, patientEvent.repeatByMonthsCount * i);

                // condition to separate showing events with journal record and with not sent or awaiting to send notification
                if (hasJournal ? (now < eventDate) : (lastDate <= eventDate)) {
                    eventArray.push({
                        title: patientEvent.description,
                        start: convertDateToCalendarDate(eventDate),
                        groupId: patientEvent._id,
                        backgroundColor: getFutureEventBackgroundColor(patientEvent, eventDate),
                    })
                }
            }

            break
        }


        default: {
            break
        }
    }

    if (specificTitle) {
        eventArray = eventArray.map((event) => {
            event.title = specificTitle;
            return event;
        })
    }

    if (specificUrl) {
        eventArray = eventArray.map((event) => {
            event.url = specificUrl;
            return event;
        })
    }

    return eventArray;
};

const getFutureEventBackgroundColor = (patientEvent: PatientEvent, eventDate: Date): string => {
    let backgroundColor = ''

    if (patientEvent.eventType === EventTypes.REMINDER || patientEvent.eventType === EventTypes.REMINDER_VO) {
        backgroundColor = 'pink'
    } else if ((patientEvent.cancellationTimeframeFrom && patientEvent.cancellationTimeframeTo) &&
        (eventDate > new Date(String(patientEvent.cancellationTimeframeFrom))) &&
        (eventDate < new Date(String(patientEvent.cancellationTimeframeTo)))
    ) {
        backgroundColor = 'black'
    }

    return backgroundColor;
};

//increase date by input days count (calendar format)
const increaseDateByDayCount = (date: Date, count: number): Date => {
    const currentDateMS: number = date.getTime();
    const increasedDateMS: number = currentDateMS + (count * oneDayMS);
    //getting new date with increase by day count
    const newDate = new Date(increasedDateMS);
    return newDate;
};

const increaseDateByMonthCount = (date: Date, count: number): Date => {
    return moment(date).add(count, "months").toDate();
};

//returns count of days between event start and end dates, depend on repeat rate (for daily repeat type only)
const getCountOfRepeatDaysByEndDate = (startDate: Date, endDate: Date, rate: number): number => {
    if (rate === 0) {
        return 0;
    }
    return (endDate.getTime() - startDate.getTime()) / oneDayMS / rate;
}

//returns count of weeks between event start and end dates, depend on repeat rate (for weekly repeat type only)
const getCountOfRepeatWeeksByEndDate = (startDate: Date, endDate: Date, rate: number): number => {
    if (rate === 0) {
        return 0;
    }
    return (endDate.getTime() - startDate.getTime()) / oneDayMS / 7 / rate;
}

const getCountOfRepeatMonthsByEndDate = (startDate: Date, endDate: Date, rate: number): number => {
    if (rate === 0) {
        return 0;
    }
    const startAt = moment(startDate);
    const finishAt = moment(endDate);
    return finishAt.diff(startAt, "months", true).valueOf() / rate;

}

//one day long in milliseconds
const oneDayMS: number = 24 * 60 * 60 * 1000;

//enums day of week
export const getDayOfWeekNumber = (day: string): number => {
    switch (day) {
        case DaysOfWeek.MONDAY: {
            return 1
        }
        case DaysOfWeek.TUESDAY: {
            return 2
        }
        case DaysOfWeek.WEDNESDAY: {
            return 3
        }
        case DaysOfWeek.THURSDAY: {
            return 4
        }
        case DaysOfWeek.FRIDAY: {
            return 5
        }
        case DaysOfWeek.SATURDAY: {
            return 6
        }
        case DaysOfWeek.SUNDAY: {
            return 0
        }

        default: {
            return -1
        }
    }
};

//return count of days between start date and first day of event
const getEventAndStartDayDifference = (startDay: number, eventDay: number, rate: number): number => {
    //if event first day already been in current week, will get day on next week
    if (eventDay === 0 && startDay > 0) {
        return 7 - startDay; // strange Sunday case
    } else if (eventDay >= startDay) {
        return eventDay - startDay;
    } else {
        return (7 * rate) + eventDay - startDay;
    }
};

//increase date to given next day of week
const getNextDayOfEvent = (date: Date, dayOfWeek: string, rate: number): Date => {
    const startDateDayOfWeek: number = date.getDay();
    const day: number = getDayOfWeekNumber(dayOfWeek);

    //checks if day of event day not matches days of week
    if (day === -1) {
        return increaseDateByDayCount(date, 0);
    }

    //get count of days to increase date
    const increaseDayCount: number = getEventAndStartDayDifference(startDateDayOfWeek, day, rate);

    return increaseDateByDayCount(date, increaseDayCount);
};

//calculate last date of event for weekly repeat type event
export const getPatientWeeklyEventEndDate = (startDate: Date, rate: number, counts: number, repeatDays: string[]): Date => {
    let endDateArray: Date[] = [];

    //count of event dates
    let dayCount: number = 0;
    //count of event weeks
    let weekCount: number = 0;

    // rearrange repeatDays to start from specific day of week
    let currentDayOfWeek = startDate.getDay();
    let nextRepeatDaysDay = 0;
    for (let i = 0; i < repeatDays.length; i++) {
        if (getDayOfWeekNumber(repeatDays[i]) >= currentDayOfWeek) {
            nextRepeatDaysDay = i;
            break;
        }
    }
    if (nextRepeatDaysDay >= 0)
        repeatDays = repeatDays.slice(nextRepeatDaysDay, repeatDays.length).concat(repeatDays.slice(0, nextRepeatDaysDay));


    //create array of event dates
    for (let i = 0; i < counts; i++) {
        endDateArray.push(increaseDateByDayCount(getNextDayOfEvent(startDate, repeatDays[dayCount], rate), rate * 7 * weekCount));

        dayCount++;

        if (dayCount >= repeatDays.length) {
            weekCount++;
            dayCount = 0;
        }
    }

    endDateArray.sort();

    //get last date in events dates array
    const newDate: Date = endDateArray[endDateArray.length - 1];

    return newDate;
};

//calculate end date of daily repeat event type by repeat rate and count
export const getPatientDailyEventEndDate = (startDate: Date, rate: number, counts: number): Date => {
    return increaseDateByDayCount(startDate, (counts - 1) * rate);
};

//calculate end date of monthly repeat event type by repeat rate and count
export const getPatientMonthlyEventEndDate = (startDate: Date, rate: number, counts: number): Date => {
    return moment(startDate).add((counts - 1) * rate, "months").toDate();
};

//convert patient event journal record to calendar format
const convertJournalRecordToCalendarEvent = (record: EventJournalRecord, eventId: string): CalendarEvent => {
    let description = record.description;
    if (record.statusDate !== undefined) {
        let d = new Date(record.statusDate);
        description = description + `\nСтатус изменен: ${moment(d).tz(getTZ()).format("DD.MM.YYYY HH:mm:ss")}`;
    }
    return {
        title: description,
        start: convertDateToCalendarDate(new Date(record.date)),
        groupId: eventId,
        backgroundColor: getBackgroundColor(record.status)
    };
};

//return event color depend on record status
const getBackgroundColor = (status: string): string => {
    switch (status) {
        case JournalStatusType.Awaiting: {
            return 'orange'
        }

        case JournalStatusType.Confirmed: {
            return 'green'
        }

        case JournalStatusType.Declined: {
            return 'red'
        }

        case JournalStatusType.Missed: {
            return 'grey'
        }

        case JournalStatusType.ScheduledDeclined: {
            return 'black'
        }

        default: {
            return ''
        }
    }
};