import React from "react";
import {PatientFile, UserFileType} from "../../dashboard";
import agent from "../../../agent";
import FileTable from "../../fileTable";
import {Loader} from "semantic-ui-react";
import PatientFilesUpload from "../PatientFilesUpload";

interface Props {
    id: string;
}

interface State {
    loading: boolean,
    showUploadDialog: boolean,
    patientFileList: PatientFile[],
    userFileTypes: UserFileType[]
}

class PatientFilesTab extends React.PureComponent<Props, State> {
    state: State = {
        loading: true,
        patientFileList: [],
        showUploadDialog: false,
        userFileTypes: []
    };

    async componentDidMount() {
        await this.onMount();
    };

    onMount = async (): Promise<void> => {
        const list: PatientFile[] = await agent.FileProvider.getList(this.props.id);
        const userFileTypes: UserFileType[] = (await agent.UserFileTypeProvider.getList()).docs;

        this.setState({
            patientFileList: list,
            userFileTypes: userFileTypes,
            loading: false
        });
    };

    render() {
        return (
            this.state.loading ?
                <Loader active/>
                :
                <div>
                    {this.state.showUploadDialog && <PatientFilesUpload
                        patientId={this.props.id}
                        onClose={async (success) => {
                            this.setState({showUploadDialog: false});
                            if (success) {
                                this.setState({
                                    loading: true,
                                    patientFileList: []
                                });
                                await this.onMount();
                            }
                        }}/>}
                    <FileTable filesList={this.state.patientFileList} userFileTypes={this.state.userFileTypes}
                               onAddClick={() => {
                                   this.setState({showUploadDialog: true});
                               }}/>
                </div>
        )
    }
}

export default PatientFilesTab;