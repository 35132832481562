import jwt_decode from "jwt-decode";
import {JwtPayload} from "../helpers/UserRole";
import moment from "moment";
import "moment-timezone/index";
import {Utils} from "../helpers/Utils";

export const getToken = (): string => {
    if (localStorage.getItem('token') !== null) {
        return localStorage.getItem('token')!;
    } else {
        return '';
    }
};

export const removeToken = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('doctorid');
    localStorage.removeItem('roleEnableScan');
    localStorage.removeItem('roleEnableFileUpload');
};

export const setJwtToken = (jwtToken: any): void => {
    localStorage.setItem('token', jwtToken);
    const decoded = jwt_decode(jwtToken) as JwtPayload;
    if (decoded.doctorid)
        localStorage.setItem('doctorid', decoded.doctorid);
    localStorage.setItem('roleEnableScan', decoded.roleEnableScan.toString());
    localStorage.setItem('roleEnableFileUpload', decoded.roleEnableFileUpload.toString());
};

export const getDoctorId = (): string | null => {
    if (localStorage.getItem('doctorid') !== null) {
        return localStorage.getItem('doctorid')!;
    } else {
        return null;
    }
};

export const getRoleEnableScan = (): boolean => {
    return (localStorage.getItem('roleEnableScan') === "true");
};

export const getRoleEnableFileUpload = (): boolean => {
    return (localStorage.getItem('roleEnableFileUpload') === "true");
};

export const getAddress = (): string => {
    return window.config?.serviceUrl ?? (process.env.REACT_APP_SERVER ? `${process.env.REACT_APP_SERVER}/api` : `http://localhost:8080/api`);
};

export const getTZ = (): string => {
    return window.config?.tz ?? 'Europe/Moscow';
};

export const convertMaskedDateStringToDate = (dateString: string): Date | undefined => {
    const dateArray: string[] = dateString.split(".");
    //debugger;
    if (dateArray.length == 3) {
        const d = Date.UTC(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0]), 12, 0, 0);
        if (d && d != NaN)
            return new Date(d);
    }
    return undefined;
};

export const convertDateToMaskedDate = (date: Date): string => {
    return `${convertNumberLengthToLengthOfTwo(date.getDate())}.${convertNumberLengthToLengthOfTwo(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export const convertDateToUtcMaskedDate = (date: Date): string => {
    return `${convertNumberLengthToLengthOfTwo(date.getUTCDate())}.${convertNumberLengthToLengthOfTwo(date.getUTCMonth() + 1)}.${date.getUTCFullYear()}`;
};

export const EmptyID: string = "000000000000000000000000";

export const NullDate: Date = new Date(Date.UTC(1001, 0, 1, 12, 0, 0));

export const safeParseInt = (input: string): number => {
    const value: number = parseInt(input);

    if (isNaN(value)) {
        return 0;
    } else {
        return value < 0 ? 0 : value;
    }
};

export const convertNumberLengthToLengthOfTwo = (dateNumber: number): string => {
    if (dateNumber < 10) {
        //add "0" before 1-length digit for full calendar accepting format
        return `0${dateNumber}`;
    } else if (dateNumber > 99) {
        //remove unnecessary digits
        return `${dateNumber % 100}`;
    } else {
        return dateNumber.toString();
    }
};

export enum DoctorTitleCase {
    a,
    om,
    ov,
    im,
    tab
}

export enum PatientTitleCase {
    a,
    u,
    ov,
    im,
    tab
}

export function getDoctorTitle(titleCase: DoctorTitleCase) {
    console.log(`getDoctorTitle(${titleCase}) , window.config?.DoctorTitle_a = ${window.config?.DoctorTitle_a}`);
    switch (titleCase) {
        case DoctorTitleCase.a:
            return window.config?.DoctorTitle_a ?? (Utils.vomode() ? "соц. куратора" : "врача");
        case DoctorTitleCase.om:
            return window.config?.DoctorTitle_om ?? (Utils.vomode() ? "соц. куратором" : "врачом");
        case DoctorTitleCase.ov:
            return window.config?.DoctorTitle_ov ?? (Utils.vomode() ? "соц. кураторов" : "врачей");
        case DoctorTitleCase.im:
            return window.config?.DoctorTitle_im ?? (Utils.vomode() ? "соц. куратор" : "врач");
        case DoctorTitleCase.tab:
            return window.config?.DoctorTitle_tab ?? (Utils.vomode() ? "Соц. кураторы" : "Врачи");
    }
}

export function getPatientTitle(titleCase: PatientTitleCase) {
    switch (titleCase) {
        case PatientTitleCase.a:
            return window.config?.PatientTitle_a ?? (Utils.vomode() ? "гражданина" : "пациента");
        case PatientTitleCase.u:
            return window.config?.PatientTitle_u ?? (Utils.vomode() ? "гражданину" : "пациенту");
        case PatientTitleCase.ov:
            return window.config?.PatientTitle_ov ?? (Utils.vomode() ? "граждан" : "пациентов");
        case PatientTitleCase.im:
            return window.config?.PatientTitle_im ?? (Utils.vomode() ? "гражданин" : "пациент");
        case PatientTitleCase.tab:
            return window.config?.PatientTitle_tab ?? (Utils.vomode() ? "Граждане" : "Пациенты");
    }
}


