import React, {ChangeEvent} from "react";
import styles from "../index.module.css";
import {Button, Dropdown, Form, Grid, Loader, Message, TextArea} from "semantic-ui-react";
import agent from "../../../agent";
import {Doctor, DoctorOptions, Patient} from "../../dashboard";
import {Redirect} from "react-router-dom";
import ReactInputMask from "react-input-mask";
import {
    convertMaskedDateStringToDate,
    DoctorTitleCase,
    EmptyID,
    getDoctorId,
    getDoctorTitle,
    getPatientTitle,
    NullDate,
    PatientTitleCase
} from "../../../utils/common";
import {DoctorPaginationResponse} from "../../workTableDoctors/DoctorsList";
import DatePicker from "react-datepicker";
import {hasRole, UserRole} from "../../../helpers/UserRole";
import {Utils} from "../../../helpers/Utils";

interface ExpirationCodeResponse {
    codeexpiredat: Date,
}

interface Props {
    match?: { path: string };
    id: string;
    backUrl: string;
}

interface State {
    editable: boolean,
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    loading: boolean,
    saved: boolean,
    editMode: boolean,
    dateofbirth: string,
    diagnosis: string,
    diary?: string,
    code: number,
    codeexpiredat: Date,
    activated: boolean,
    doctorid: string,
    codeLoading: boolean,
    doctors: Doctor[],
    inactive: boolean,
    inactiveUntil?: Date,
    isLargeFamily?: boolean,
    inn?: string,
    botblocked?: boolean,
    telValid: boolean,
    emailValid: boolean,
}

class PatientInfoTab extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        name: '',
        middlename: '',
        surname: '',
        tel: '',
        email: '',
        loading: false,
        saved: false,
        dateofbirth: '',
        diagnosis: '',
        code: 0,
        codeexpiredat: new Date(0),
        activated: false,
        doctorid: '',
        codeLoading: false,
        doctors: [],
        editMode: false,
        inactive: false,
        botblocked: false,
        telValid: true,
        emailValid: true,
    };

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true});
            await this.onMount();
        } else {
            this.setState({loading: true})
            const response: DoctorPaginationResponse = await agent.DoctorProvider.getList();
            this.setState({doctors: response.docs, loading: false, editable: true})
            const doctorId = await getDoctorId();
            if (doctorId)
                this.setState({
                    doctorid: doctorId,
                });
        }
    };

    onMount = async (): Promise<void> => {
        const patient: Patient = await agent.PatientProvider.open(this.props.id);

        if (patient) {
            this.setState({
                _id: patient._id,
                name: patient.name,
                middlename: patient.middlename,
                surname: patient.surname,
                tel: patient.tel,
                email: patient.email,
                dateofbirth: new Date(patient.dateofbirth).toLocaleDateString("RU"),
                diagnosis: patient.diagnosis,
                diary: patient.diary,
                isLargeFamily: patient.isLargeFamily,
                code: patient.code ? patient.code : 0,
                codeexpiredat: patient.codeexpiredat ? patient.codeexpiredat : new Date(0),
                activated: patient.activated ? patient.activated : false,
                doctorid: patient.doctorid ? patient.doctorid : '',
                inactive: patient.inactive === true,
                inactiveUntil: patient.inactiveUntil ? new Date(patient.inactiveUntil!) : undefined,
                inn: patient.inn,
                botblocked: patient.botblocked === true
            });

            const response: DoctorPaginationResponse = await agent.DoctorProvider.getList();

            this.setState({
                doctors: response.docs,
                loading: false,
            });
        }
    };

    onCreate = async (): Promise<void> => {
        this.setState({loading: true});

        await agent.PatientProvider.create({
            _id: this.state._id,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            dateofbirth: !!convertMaskedDateStringToDate(this.state.dateofbirth) ?
                convertMaskedDateStringToDate(this.state.dateofbirth)! : NullDate,
            diagnosis: this.state.diagnosis,
            diary: this.state.diary,
            isLargeFamily: this.state.isLargeFamily,
            doctorid: this.state.doctorid ? this.state.doctorid : EmptyID,
            inn: this.state.inn
        });

        this.setState({loading: false, saved: true});
    };

    onEdit = async () => {
        this.setState({loading: true});

        await agent.PatientProvider.edit({
            _id: this.state._id,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            dateofbirth: convertMaskedDateStringToDate(this.state.dateofbirth) ?
                convertMaskedDateStringToDate(this.state.dateofbirth)! : NullDate,
            diagnosis: this.state.diagnosis,
            doctorid: this.state.doctorid ? this.state.doctorid : EmptyID,
            inactive: this.state.inactive,
            inactiveUntil: this.state.inactiveUntil,
            diary: this.state.diary,
            isLargeFamily: this.state.isLargeFamily,
            inn: this.state.inn,
            botblocked: this.state.botblocked
        }).then((patient: Patient) => {
            this.setState({
                _id: patient._id,
                doctorid: patient.doctorid ? patient.doctorid : '',
                name: patient.name,
                middlename: patient.middlename,
                surname: patient.surname,
                tel: patient.tel,
                email: patient.email,
                dateofbirth: new Date(patient.dateofbirth).toLocaleDateString("RU"),
                diagnosis: patient.diagnosis,
                diary: patient.diary,
                isLargeFamily: patient.isLargeFamily,
                code: patient.code ? patient.code : 0,
                inn: patient.inn,
                codeexpiredat: patient.codeexpiredat ? patient.codeexpiredat : new Date(0),
                activated: patient.activated ? patient.activated : false,
            });
        });

        this.setState({loading: false, editable: false});
    };

    getDoctorsOptions = (): DoctorOptions[] => {
        return this.state.doctors.map((el: Doctor) => ({
            key: el._id,
            text: `${el.surname} ${el.name} ${el.middlename}`,
            value: el._id,
        }))
    };

    getDefaultDoctor = (): string => {
        let doctor: string = '';

        this.state.doctors && this.state.doctors.forEach((doc: Doctor) => {
            if (doc._id === this.state.doctorid) {
                doctor = `${doc.surname} ${doc.name} ${doc.middlename}`

                return
            }
        });

        return doctor;
    };

    getSelectedDoctorFullName = (): string => {
        const doctor: Doctor | undefined = this.state.doctors.find((el: Doctor) => el._id === this.state.doctorid);

        return doctor ? `${doctor.surname} ${doctor.name} ${doctor.middlename}` : '';
    };

    getCodeExpirationLabel = (): string => {
        const date: Date = new Date(this.state.codeexpiredat);

        if (this.state.codeexpiredat && new Date() < date) {
            return `Код активации. Истекает: ${date.toLocaleString("RU")}`
        } else {
            return `Код активации истёк`
        }
    };

    onRefreshCodeExpiration = async (): Promise<void> => {
        this.setState({codeLoading: true});

        const response: ExpirationCodeResponse = await agent.PatientProvider.refreshCodeExpiration(this.state._id);

        if (response) {
            this.setState({codeexpiredat: response.codeexpiredat})
        }

        this.setState({codeLoading: false});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.surname
    };

    copyDataToClipboard = () => {
        const el = document.createElement("textarea");
        el.value = `${this.state.surname} ${this.state.name[0]}.${this.state.middlename[0]}. ${this.state.diagnosis}`;
        el.style.display = "hidden";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }

    render() {
        const NilObjectId: string = '000000000000000000000000';

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/patients'}/>
                :
                <div className={this.state.editMode ? styles.patientInfoContent : styles.patientCreating}>
                    {
                        this.state.editMode ? ''
                            :
                            <div className={styles.patientInfoHeader}>
                                {getPatientTitle(PatientTitleCase.a)}
                            </div>

                    }
                    {
                        this.state.loading ?
                            <Loader active>Список загружается...</Loader>
                            :
                            <Form>
                                <Grid stackable columns={3} stretched>

                                    <Grid.Column>
                                        {
                                            this.state.editable ?
                                                <Button
                                                    color={'green'}
                                                    type={'submit'}
                                                    content={'Сохранить'}
                                                    disabled={this.isSaveDisabled()}
                                                    onClick={this.state.editMode ? this.onEdit : this.onCreate}
                                                />
                                                :
                                                <Button
                                                    color={'teal'}
                                                    type={'submit'}
                                                    content={'Изменить'}
                                                    onClick={() => this.setState({editable: true})}
                                                />
                                        }
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            color={'violet'}
                                            basic
                                            content={'Копировать данные'}
                                            onClick={this.copyDataToClipboard}
                                        />
                                    </Grid.Column>

                                    {
                                        this.state.editMode ?

                                            <Grid.Column>
                                                <div className={styles.codeInfo}>
                                                    {
                                                        this.state.activated ?
                                                            <Message
                                                                size={'mini'}
                                                                header={Utils.vomode() ? 'Бот активирован' : 'Пациент активирован'}
                                                            />
                                                            :
                                                            <>
                                                                <Form.Input label={this.getCodeExpirationLabel()}
                                                                            value={this.state.code}
                                                                            readOnly/>
                                                                <Form.Button floated={'left'}
                                                                             color={'blue'}
                                                                             fluid
                                                                             loading={this.state.codeLoading}
                                                                             onClick={this.onRefreshCodeExpiration}>
                                                                    Продлить срок действия кода
                                                                </Form.Button>
                                                            </>
                                                    }
                                                </div>
                                            </Grid.Column>
                                            :
                                            ''
                                    }
                                </Grid>
                                <Grid stackable columns={4} stretched>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Неактивный {getPatientTitle(PatientTitleCase.im)}</label>
                                            <Form.Checkbox
                                                checked={this.state.inactive}
                                                readOnly={!this.state.editable}
                                                toggle
                                                onChange={(event, data) =>
                                                    this.setState({
                                                        inactive: data.checked === true,
                                                        inactiveUntil: data.checked ?
                                                            this.state.inactiveUntil : undefined
                                                    })}/>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field disabled={!this.state.inactive}>
                                            <label>Неактивен до</label>
                                            <DatePicker selected={this.state.inactiveUntil}
                                                        locale={"ru"}
                                                        dateFormat={"dd.MM.yyyy"}
                                                        disabled={!this.state.inactive}
                                                        onChange={(v: Date) => {
                                                            this.setState({
                                                                inactiveUntil: v
                                                            })
                                                        }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Многодетная семья</label>
                                            <Form.Checkbox
                                                checked={this.state.isLargeFamily}
                                                readOnly={!this.state.editable}
                                                toggle
                                                fitted

                                                onChange={(event, data) =>
                                                    this.setState({
                                                        isLargeFamily: data.checked === true
                                                    })}/>
                                        </Form.Field>
                                    </Grid.Column>
                                    {window.config?.moniki == true && hasRole(UserRole.ADMIN) &&
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Бот заблокирован</label>
                                        <Form.Checkbox
                                            checked={this.state.botblocked}
                                            readOnly={!this.state.editable}
                                            toggle
                                            onChange={(event, data) =>
                                                this.setState({
                                                    botblocked: data.checked === true
                                                })}/>
                                        </Form.Field>

                                    </Grid.Column>}

                                </Grid>

                                <Form.Group widths={"equal"}>
                                        <Form.Input label={'Фамилия'}
                                                    fluid
                                                    value={this.state.surname}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({surname: e.target.value})}
                                        />
                                        <Form.Input label={'Имя'}
                                                    value={this.state.name}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        />
                                        <Form.Input label={'Отчество'}
                                                    value={this.state.middlename}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({middlename: e.target.value})}
                                        />
                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                <Form.Field>
                                    <label>Дата рождения</label>
                                    <ReactInputMask
                                        mask={'99.99.9999'}
                                        alwaysShowMask={false}
                                        value={this.state.dateofbirth}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({dateofbirth: e.target.value})}
                                    />
                                </Form.Field>
                                {Utils.vomode() &&
                                <Form.Input label={'ИНН'}
                                            value={this.state.inn}
                                            readOnly={!this.state.editable}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({inn: e.target.value})}
                                />}
                                {/*<Form.Input label={'Номер телефона'}*/}
                                {/*            value={this.state.tel}*/}
                                {/*            readOnly={!this.state.editable}*/}
                                {/*            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({tel: e.target.value})}*/}
                                {/*/>*/}
                                    <Form.Input
                                        label="Номер телефона"
                                        value={this.state.tel}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            //Требуемая длина от 5 до 20 цифр/пробелов
                                            const inputValue = e.target.value;
                                            const isNonEmpty = inputValue.trim() !== '';
                                            const filteredValue = isNonEmpty ? inputValue.replace(/[^0-9\s]/g, '') : '';
                                            const length = filteredValue.length;
                                            const isValid = isNonEmpty ? length >= 5 && length <= 20 : true;
                                            this.setState({
                                                tel: filteredValue,
                                                telValid: isValid,
                                            });
                                        }}
                                        error={!this.state.telValid}
                                        content={!this.state.telValid && this.state.tel !== '' && "Требуется длина от 5 до 20 цифр/пробелов"}
                                    />
                                {/*    <Form.Input label={'Электронная почта'}*/}
                                {/*            value={this.state.email}*/}
                                {/*            readOnly={!this.state.editable}*/}
                                {/*            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({email: e.target.value})}*/}
                                {/*/>*/}
                                    <Form.Input
                                        //Проверка на @, точку и по хотя бы одному англ символу и цифр между
                                        label="Электронная почта"
                                        value={this.state.email}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const inputValue = e.target.value;
                                            const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue);

                                            this.setState({
                                                email: inputValue,
                                                emailValid: isValid,
                                            });
                                        }}
                                        error={!this.state.emailValid}
                                        content={!this.state.emailValid && "Введите правильный адрес электронной почты"}
                                    />
                                </Form.Group>

                                {
                                    this.state.editable ?
                                        <Form.Field>
                                            <label>{getDoctorTitle(DoctorTitleCase.im)}</label>
                                            <Dropdown
                                                placeholder={(this.state.doctorid && this.state.doctorid !== NilObjectId) ? this.getDefaultDoctor() :
                                                    `Выберите ${getDoctorTitle(DoctorTitleCase.a)}`}
                                                search
                                                selection
                                                clearable
                                                disabled={!this.state.editable || !hasRole(UserRole.ADMIN)}
                                                options={this.getDoctorsOptions()}
                                                onChange={(e, data) => this.setState({doctorid: data.value as string})}
                                            />
                                        </Form.Field>
                                        :
                                        <Form.Input label={getDoctorTitle(DoctorTitleCase.im)}
                                                    value={this.getSelectedDoctorFullName()}
                                                    readOnly/>

                                }

                                <Form.Field>
                                    <label>{Utils.vomode() ? "Описание ситуации" : "Диагноз"}</label>
                                    <Form.Input as={TextArea}
                                                control={'string'}
                                                rows={2}
                                                value={this.state.diagnosis}
                                                readOnly={!this.state.editable}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({diagnosis: e.target.value})}
                                    />

                                </Form.Field>
                                <Form.Field>
                                    <label>Дневник</label>
                                    <Form.Input as={TextArea}
                                                control={'string'}
                                                rows={16}
                                                value={this.state.diary}
                                                readOnly={!this.state.editable}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({diary: e.target.value})}
                                    />

                                </Form.Field>

                            </Form>
                    }
                </div>
        )
    }

}

export default PatientInfoTab;