import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Dimmer, Form, InputOnChangeData, Loader, Modal} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../agent";

interface Props {
    onClose: (success: Boolean) => void;
}

const ChangeAdminPasswordModal = (props: Props) => {
    const [enabled, setEnabled] = useState(false);
    const [changing, setChanging] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    useEffect(() => {
        setEnabled(oldPassword.length > 3 && newPassword.length > 3 && newPasswordRepeat == newPassword)
    }, [oldPassword, newPassword, newPasswordRepeat]);

    return (
        <Modal
            onClose={() => props.onClose(false)}
            open={true}
            closeIcon>
            <Modal.Header>Смена пароля администратора</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        type={"password"}
                        label={"Введите старый пароль:"}
                        value={oldPassword}
                        onChange={((event, data) => {
                            setOldPassword(data.value);
                        })}/>
                    <Form.Input
                        type={"password"}
                        label={"Введите новый пароль:"}
                        value={newPassword}
                        onChange={((event, data) => {
                            setNewPassword(data.value);
                        })}/>
                    <Form.Input
                        type={"password"}
                        label={"Повторите новый пароль:"}
                        value={newPasswordRepeat}
                        onChange={((event, data) => {
                            setNewPasswordRepeat(data.value);
                        })}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={"Изменить"}
                    disabled={!(enabled || changing)}
                    onClick={async () => {
                        setChanging(true);
                        try {
                            let res = await agent.AuthProvider.changeAdminPassword(oldPassword, newPassword);
                            //alert(JSON.stringify(res));
                            if (!res?.error)
                            {
                                alert("Пароль администратора успешно изменён!");
                                props.onClose(true);
                            }
                        } finally {
                            setChanging(false);
                        }
                    }}
                    positive
                />
                <Button
                    color={'grey'}
                    disabled={changing}
                    onClick={() => props.onClose(false)}>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>);
}

export default ChangeAdminPasswordModal;