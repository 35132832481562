import React, {ChangeEvent, useState} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid} from 'semantic-ui-react'
import agent from "../../agent";
import {setJwtToken} from "../../utils/common";
import history from "../../history";
import request from "superagent";
import TelegramLoginButton from "react-telegram-login";
import {Utils} from "../../helpers/Utils";
import {ConfigPanelOptions} from "../configPanel";

export const Login = () => {
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const handleAuthorization = async (error: request.HTTPError, res: request.Response) => {
        if (res.status === 401 || res.status === 403) {
            setError(true);
            alert(res.body.message);
        } else {
            setJwtToken(res.body.token);
            const config: ConfigPanelOptions = await agent.ConfigProvider.get();
            history.push("/dashboard/patients");
        }
    };

    const onSubmit = async () => {
        await agent.AuthProvider.login(login, password).end(handleAuthorization);
    };

    const loginLabel: string = `Войти`;

    const inputIsCorrect = (): boolean => {
        return login.length > 0 && password.length > 0;
    };

    const handleTelegramResponse = async (data: TelegramAuthResponse) => {
        await agent.AuthProvider.telegramLogin(data).end(handleAuthorization);
    }

    return (
        <div className={styles.form}>

            <Grid className={styles.grid}>
                <Grid.Column className={styles.column}>

                    <Form size={'large'}>
                        <Form.Input
                            fluid icon={'user'}
                            iconPosition={'left'}
                            placeholder={'Логин'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                            error={error}
                        />

                        <Form.Input
                            fluid
                            icon={'lock'}
                            iconPosition={'left'}
                            placeholder={'Пароль'}
                            type={'password'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            error={error}
                        />

                        <Button
                            className={styles.button}
                            disabled={!inputIsCorrect()}
                            size='large'
                            color={'green'}
                            type={'submit'}
                            onClick={onSubmit}
                        >
                            {loginLabel}
                        </Button>
                    </Form>

                    {!(window.config?.useDoctorAuthenticationByPassword == true) && <div className={styles.widgetLogin}>
                        <TelegramLoginButton
                            botName={window.config?.telegramAuthBotName ?? process.env.REACT_APP_AUTH_BOT_NAME}
                            dataOnauth={handleTelegramResponse}/>
                    </div>}
                    {/*{window.config && <div style={{backgroundColor:"lightgray"}}>*/}
                    {/*    <h4>Config.js specified!</h4>*/}
                    {/*    <p>{JSON.stringify(window.config)}</p>*/}
                    {/*    <p>serviceUrl: {window.config.serviceUrl}</p>*/}
                    {/*    <p>telegramAuthBotName: {window.config.telegramAuthBotName}</p>*/}
                    {/*</div>}*/}
                </Grid.Column>
            </Grid>

        </div>
    )
}
