import React, {ChangeEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid, Icon, Label, Loader, Menu, Modal, Table} from "semantic-ui-react";
import agent from "../../agent";
import {Link, Redirect} from "react-router-dom";
import {Test, TestIndicator} from "./TestModel";
import {TextAreaProps} from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import TestIndicatorEditModal from "./TestIndicatorEditModal";

interface Props {
    indicators?: TestIndicator[];
    editing: boolean;
}

interface State {
    indicators: TestIndicator[];
    indicator?: TestIndicator,
    editIndicator?: TestIndicator,
    isNew?: boolean,
    removeIndicator?: TestIndicator,
}

class TestIndicatorList extends React.PureComponent<Props, State> {
    state: State = {
        indicators: this.props.indicators ?? []
    };


    render() {
        return (
            <div style={{marginTop: "10px"}}>
                {this.props.editing &&
                <Form.Button color='blue' content='Добавить'
                             size={"mini"}
                             width={"10"}
                             onClick={() => {
                                 this.setState({
                                     isNew: true,
                                     indicator: {
                                         id: "",
                                         num: Math.max(...this.state.indicators.map((x) => x.num.valueOf())) + 1,
                                         code: "",
                                         name: "",
                                         nameEng: "",
                                     }
                                 })
                             }}/>
                }

                {this.removeModalContent()}

                {!!this.state.indicator &&
                <Modal
                    onClose={() => this.setState({indicator: undefined})}
                    open={!!this.state.indicator}
                    closeIcon>
                    <Modal.Header>Показатель</Modal.Header>
                    <Modal.Content>
                        <TestIndicatorEditModal indicator={this.state.indicator}
                                                onChange={((v) => this.setState({
                                                    editIndicator: v
                                                }))}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content={"Сохранить"}
                            onClick={() => {
                                if (this.state.editIndicator != null) {
                                    if (this.state.isNew)
                                        this.state.indicators.push(this.state.editIndicator);
                                    else {
                                        Object.assign(this.state.indicator, this.state.editIndicator);
                                    }
                                }
                                this.setState({
                                    indicator: undefined,
                                    editIndicator: undefined,
                                    indicators: this.state.indicators
                                });
                            }}
                            positive
                        />
                        <Button
                            color={'grey'}
                            onClick={() => this.setState({indicator: undefined})}>
                            Отмена
                        </Button>
                    </Modal.Actions>
                </Modal>}
                <div className={styles.indicatorList}>
                    <Table celled size={"small"} stackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>№</Table.HeaderCell>
                                <Table.HeaderCell>Код</Table.HeaderCell>
                                <Table.HeaderCell>Наименование</Table.HeaderCell>
                                <Table.HeaderCell>Наименование (иностр.)</Table.HeaderCell>
                                <Table.HeaderCell>Ед.изм.</Table.HeaderCell>
                                <Table.HeaderCell>Min</Table.HeaderCell>
                                <Table.HeaderCell>Max</Table.HeaderCell>
                                {this.props.editing &&
                                <Table.HeaderCell></Table.HeaderCell>}
                                {this.props.editing && <Table.HeaderCell></Table.HeaderCell>}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.indicators.map((el, index) =>
                                <Table.Row key={`key-${index}-${el.code}`}>
                                    <Table.Cell>{el.num}</Table.Cell>
                                    <Table.Cell>{el.code}</Table.Cell>
                                    <Table.Cell>{el.name}</Table.Cell>
                                    <Table.Cell>{el.nameEng}</Table.Cell>
                                    <Table.Cell textAlign={"right"}>{el.units}</Table.Cell>
                                    <Table.Cell textAlign={"right"}>{el.min}</Table.Cell>
                                    <Table.Cell textAlign={"right"}>{el.max}</Table.Cell>
                                    {this.props.editing &&
                                    <Table.Cell floated={'right'} style={{width: "5%"}}>
                                        <Button icon
                                                onClick={() => {
                                                    this.setState({
                                                        indicator: el,
                                                        isNew: false
                                                    });
                                                }}

                                        >
                                            <Icon color={"blue"} name='edit outline'/>
                                        </Button>

                                    </Table.Cell>
                                    }
                                    {this.props.editing && <Table.Cell floated={'right'} style={{width: "5%"}}>
                                        <Button icon
                                                onClick={() => this.setState({
                                                    removeIndicator: el
                                                })}
                                        >
                                            <Icon color={"red"} name='trash alternate outline'/>
                                        </Button>

                                    </Table.Cell>}
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
            </div>)
    }

    private removeModalContent() {
        return !!this.state.removeIndicator &&
            <Modal
                onClose={() => this.setState({removeIndicator: undefined})}
                open={!!this.state.removeIndicator}
                closeIcon
            >
                <Modal.Content>Удалить показатель?</Modal.Content>
                <Modal.Actions>
                    <Button
                        content={"Удалить"}
                        onClick={this.removeTestIndicator}
                        positive
                    />
                    <Button
                        color={'grey'}
                        onClick={() => this.setState({removeIndicator: undefined})}
                    >
                        Отмена
                    </Button>
                </Modal.Actions>
            </Modal>

    }

    removeTestIndicator = async () => {

        if (!!this.state.removeIndicator) {
            var index = this.state.indicators.indexOf(this.state.removeIndicator);
            this.state.indicators.splice(index, 1);
            this.setState({
                indicators: this.state.indicators,
                removeIndicator: undefined
            });
        }
    }
}

export default TestIndicatorList;