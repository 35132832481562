import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import DataGrid, {CellNavigationMode, Column, TextEditor} from 'react-data-grid';
import {Test, TestIndicator} from "../../../test/TestModel";
import styles from "../../index.module.css";
import {TestResult} from "../../../test/TestResultModel";
import agent from "../../../../agent";
import {Button, Container, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Utils} from "../../../../helpers/Utils";

interface Props {
    patientId: string;
    test: Test;
    testResults: TestResult[];
    onAdd?: () => void;
    onEdit?: (testResult: TestResult) => void;
    onDelete?: (testResult: TestResult) => void;
}

const PatientTestsResultsView = (props: Props) => {
    const [rows, setRows] = useState<Object[]>([]);
    const [cols, setCols] = useState<Column<any>[]>(populateCols());
    const testResultsMap: { [key: string]: TestResult } = {};
    const [testResult, setTestResult] = useState<TestResult>();
    const rowsRef = useRef<Object[]>([]);
    const [fileId, setFileId] = useState<string | undefined>(undefined);



    function populateRows() {
        return props.test.indicators.map(value => {
            let row = {
                indicator: value.name,
                units: value.units,
                code: value.code,
                id: value.id,
                refs: Utils.getRefsText(value),
                min: value.min,
                max: value.max
            };

            props.testResults.forEach((tr) =>
                tr.values?.forEach((trv) => {
                    if (trv.code == value.code) {
                        (row as any)["testResult_" + tr._id] = trv.value;
                    }
                }));

            return row;
        });
    }

    //
    // useEffect(() => {
    //     setRows(populateRows());
    // }, [props.test]);

    function getTestResultName(value: TestResult): string {
        let result = "";
        if (value.date != null) {
            result += (new Date(value.date!)).toLocaleDateString("ru-RU");
            // if ((value.name?.length ?? 0) > 0)
            //     result += " (" + value.name! + ")"
        }
        if (result.length == 0)
            result = "?";
        return result;
    }

    function onDeleteClick(key: string) {
        if (testResultsMap[key] && props.onDelete) {
            props.onDelete!(testResultsMap[key]);
        }
    }

    let KEY_HEADER = 'testResult_';

    function onEditClick(key: string) {
        if (testResultsMap[key] && props.onEdit) {
            props.onEdit!(testResultsMap[key]);
        }
    }

    function onEditValuesClick(key: string) {
        if (testResultsMap[key]) {
            let _testResult = testResultsMap[key];
            setTestResult(_testResult != testResult ? _testResult : undefined);
        }
    }

    function addCmdHeaderRenderer(cprops: PropsWithChildren<any>) {
        return <Button.Group size={"mini"} icon inverted basic compact>
            <Button onClick={props.onAdd}>
                <Icon name={"add"}
                      color="blue"
                />
            </Button>
        </Button.Group>
    }

    async function onEditValuesSaveClick() {
        await updateTestResults(rowsRef.current);
        setTestResult(undefined);
    }

    function onEditValuesCancelClick() {
        setTestResult(undefined);
    }

    async function onFileClick(key: string) {
        if (testResultsMap[key]?.fileId) {
            await Utils.openFile({
                fileId: testResultsMap[key]!.fileId!.toString()
            })
        }
    }

    function populateCols(): Column<any>[] {
        let result: Column<any, any>[] = [];
        result.push(...[{key: 'indicator', name: 'Показатель', width: 300, frozen: true, resizable: true},
                {key: 'units', name: 'ед.изм', width: 80, frozen: true, cellClass: styles.trCellRight, resizable: true},
                {key: 'refs', name: 'границы', width: 80, frozen: true, cellClass: styles.trCellRight, resizable: true},
                {
                    key: 'addcmd', name: '', minWidth: 42, width: 42, frozen: true, resizable: false,
                    headerRenderer: addCmdHeaderRenderer
                }
            ]
        )
        props.testResults?.forEach((value, i4dex) => {
            let key = KEY_HEADER + value._id;
            if (testResultsMap)
                testResultsMap[key] = value;
            //testResultsMap[key] = value;
            result.push({
                key: key,
                name: getTestResultName(value),
                width: 120,
                resizable: true,
                headerCellClass: styles.trCellCenter,
                editor: value == testResult ? TextEditor : null,

                cellClass(row) {
                    let num = parseFloat(row[key]);
                    if (testResult == value) {
                        if (!isNaN(num)) {
                            if (row["min"] && (num < row["min"]))
                                return styles.trCellEditBelow;
                            if (row["max"] && (num > row["max"]))
                                return styles.trCellEditAbove;
                        }
                        return styles.trCellEditDefault;
                    } else if (row[key]) {
                        if (!isNaN(num)) {
                            if (row["min"] && (num < row["min"]))
                                return styles.trCellBelow;
                            if (row["max"] && (num > row["max"]))
                                return styles.trCellAbove;
                        }
                    }
                    return styles.trCellDefault;
                },

                headerRenderer(props, rowType) {
                    return <p><p style={{"marginTop": "10px"}}>{props.column.name}<br/>
                        {testResult != value &&
                        <Button.Group size={"mini"} icon inverted basic compact style={{marginTop: "8px"}}>
                            <Button onClick={() => {
                                onEditValuesClick(key);
                            }}>
                                <Icon name={"content"}
                                      color="blue"
                                />
                            </Button>
                            {value.fileId &&
                            <Button onClick={() => {
                                onFileClick(key);
                            }}>
                                <Icon name={"file outline"}
                                      color="blue"
                                />
                            </Button>}
                            <Button onClick={() => {
                                onEditClick(key);
                            }}>
                                <Icon name={"edit"}
                                      color="blue"
                                />
                            </Button>
                            <Button onClick={() => {
                                onDeleteClick(key);
                            }}>
                                <Icon name={"delete"}
                                      color="brown"/>
                            </Button>
                        </Button.Group>
                        }
                        {testResult == value &&
                        <Button.Group size={"mini"} icon inverted basic compact style={{marginTop: "8px"}}>
                            <Button onClick={() => {
                                onEditValuesSaveClick().then(r => {
                                });
                            }}>
                                <Icon name={"save"}
                                      color="blue"
                                />
                            </Button>
                            <Button onClick={() => {
                                onEditValuesCancelClick();
                            }}>
                                <Icon name={"cancel"}
                                      color="brown"/>
                            </Button>
                        </Button.Group>
                        }
                    </p></p>
                }
            })
        })
        return result;
    }

    function setRowsHandler(value: Object[]) {
        rowsRef.current = value;
        setRows(value);
    }

    useEffect(() => {
        setCols(populateCols());
        setRows(populateRows());
        setFileId(undefined);
    }, [props.testResults, testResult]);

    async function updateTestResult(testResult: TestResult, rows: Object[]) {
        let testResultId: string = KEY_HEADER + testResult!._id;
        let gridValues = rows;
        let resultValues = testResult.values ?? [];
        let changed = false;
        for (const value of gridValues) {
            let code = (value as any)["code"];
            let v = (value as any)[testResultId];
            if (v && (v.toString().indexOf(",") >= 0))
                v = v.toString().replace(",", ".");
            let num: number | undefined = parseFloat(v);
            if (isNaN(num))
                num = undefined;
            let exists = resultValues?.find((iv, ivi) => iv.code == code);
            if (!exists) {
                if (v) {
                    resultValues?.push({
                        code: code,
                        value: v,
                        num: num
                    });
                    changed = true;
                }
            } else {
                if (exists.value !== v) {
                    if (v !== "") {
                        exists.value = v;
                        exists.num = num;
                        changed = true;
                    } else {
                        resultValues?.splice(resultValues.indexOf(exists), 1);
                        changed = true;
                    }
                }
            }
        }
        if (changed) {
            testResult.values = resultValues;
            await agent.TestResultProvider.edit(testResult);
        }
    }

    async function updateTestResults(rows: Object[]) {

        for (const tr of props.testResults) {
            await updateTestResult(tr, rows);
        }
    }

    return (
        <DataGrid columns={cols}
                  style={{height: "80%"}}
                  rows={rows}
                  className={"rdg-light"}
                  headerRowHeight={80}
                  onRowsChange={setRowsHandler}
        />
    );
};

export default PatientTestsResultsView;