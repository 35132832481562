import React from 'react';
import styles from "./App.module.css";
import {Main} from "./components/main/Main";
import {Router} from "react-router-dom";
import history from './history';
import {Config} from "./@types/config";

declare global {
    interface Window {
        config?: Config;
    }
}

export const App = () => {
    return (
        <div className={styles.App}>
            <Router history={history}>
                <Main/>
            </Router>
        </div>
    );
}

export default App;
