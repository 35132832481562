import React from "react";
import {Route, Switch} from "react-router";
import {Login} from "../login";
import {Auth} from "../auth";

export const Main = () => {
    return (
        <Switch>
            <Route path={"/"} exact component={Login}/>

            <Route path={"/dashboard"} component={Auth}/>
        </Switch>
    )
}