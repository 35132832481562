import React, {ChangeEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid, InputOnChangeData, Loader} from "semantic-ui-react";
import agent from "../../agent";
import {Link, Redirect} from "react-router-dom";
import {Test, TestIndicator} from "./TestModel";
import {TextAreaProps} from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import TestIndicatorList from "./TestIndicatorList";

interface Props {
    indicator: TestIndicator;
    onChange?: (value: TestIndicator) => void;
}

interface State {
    indicator: TestIndicator,
    minv: string | undefined,
    maxv: string | undefined
}

class TestIndicatorEditModal extends React.PureComponent<Props, State> {
    state: State = {
        indicator: {...this.props.indicator},
        minv: this.props.indicator.min?.toString(),
        maxv: this.props.indicator.max?.toString()
    };

    render() {
        this.props?.onChange?.call(this, this.state.indicator);
        return (
            <div>
                <Form>
                    <Form.Input
                        label={'№'}
                        value={this.state.indicator.num}
                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                            var v = parseInt(e.target.value);
                            this.setState({indicator: {...this.state.indicator, num: v}})
                            this.props?.onChange?.call(this, this.state.indicator);
                        }}/>
                            <Form.Input
                            label={'Код'}
                            value={this.state.indicator.code}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({indicator: {...this.state.indicator, code: e.target.value}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }}/>
                            <Form.Input
                            label={'Наименование'}
                            value={this.state.indicator.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({indicator: {...this.state.indicator, name: e.target.value}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }
                            }/>
                            <Form.Input
                            label={'Наименование (иностр.)'}
                            value={this.state.indicator.nameEng}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({indicator: {...this.state.indicator, nameEng: e.target.value}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }}/>
                            <Form.Input
                            label={'Единицы измерения'}
                            value={this.state.indicator.units}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({indicator: {...this.state.indicator, units: e.target.value}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }}/>
                            <Form.Input
                            label={'Минимальное значение'}
                            value={this.state.minv}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({minv: e.target.value})
                                var v = parseFloat(e.target.value);
                                this.setState({indicator: {...this.state.indicator, min: v != NaN ? v : undefined}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }}/>
                            <Form.Input
                            label={'Максимальное значение'}
                            value={this.state.maxv}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({maxv: e.target.value})
                                var v = parseFloat(e.target.value);
                                this.setState({indicator: {...this.state.indicator, max: v != NaN ? v : undefined}})
                                this.props?.onChange?.call(this, this.state.indicator);
                            }}/>
                            </Form>
                            </div>
                            )
                            }
                            }

                            export default TestIndicatorEditModal;