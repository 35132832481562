import React, {useEffect, useState} from "react";
import agent from "../../../../agent";
import {Loader, Menu} from "semantic-ui-react";
import {Test} from "../../../test/TestModel";
import {Link} from "react-router-dom";
import PatientTestsResultsView from "./PatientTestsResultsView";
import styles from "../../../fileTable/index.module.css";
import {TestResult} from "../../../test/TestResultModel";
import TestResultEditModal from "./TestResultEditModal";
import TestResultDeleteModal from "./TestResultDeleteModal";


interface Props {
    patientId: string;
}

const PatientTestResultsTab = (props: Props) => {
    const [test, setTest] = useState<Test | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [tests, setTests] = useState<Test[]>([]);
    const [testResult, setTestResult] = useState<TestResult | undefined>(undefined);
    const [deleteTestResult, setDeleteTestResult] = useState<TestResult>();
    const [testResults, setTestResults] = useState<TestResult[]>([]);

    useEffect(() => {
        async function fetchTestList() {
            let response = (await agent.TestProvider.getList());
            setTests(response.docs);
            if (!test && (response.docs.length > 0))
                setTest(response.docs[0]);
        }

        fetchTestList().then(r => {
        })
    }, []);

    async function fetchTestResultList() {
        let response = await agent.TestResultProvider.getList({
            patientId: props.patientId,
            testId: test!._id
        });
        setTestResults(response);
    }

    useEffect(() => {
        if (test) {
            fetchTestResultList().then(r => {
            });
        }
    }, [test, testResult]);

    async function onEdit(testResult: TestResult) {
        var _tr = (await agent.TestResultProvider.open(testResult._id!.toString())) as TestResult;
        if (_tr.date)
            _tr.date = new Date(Date.parse(_tr.date!.toString()));
        setTestResult(_tr!);
    }

    function onDelete(testResult: TestResult) {
        setDeleteTestResult(testResult);
    }

    function onAdd() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        setTestResult({
            testId: test!._id,
            name: "",
            date: date,
            values: []
        });
    }

    return loading ?
        <Loader active/>
        : (
            <div>
                {!!testResult && !!test &&
                <TestResultEditModal test={test!}
                                     testResult={testResult!}
                                     patientId={props.patientId}
                                     onClose={() => setTestResult(undefined)}
                />}

                {!!deleteTestResult && !!test &&
                <TestResultDeleteModal
                    testResult={deleteTestResult!}
                    onClose={async (success) => {
                        if (success) {
                            await fetchTestResultList();
                        }
                        setDeleteTestResult(undefined);
                    }}
                />}

                <Menu pointing secondary>
                    {tests.map((x) =>
                        <Menu.Item
                            as={Link}
                            name={x.name.toString()}
                            active={x === test}
                            onClick={() => setTest(x)}
                        />)}
                </Menu>
                {/*<div style={{marginBottom: "10px"}}>*/}
                {/*    <Form.Button color='blue' content='Добавить'*/}
                {/*                 size={"mini"}*/}
                {/*                 width={"10"}*/}
                {/*                 onClick={() => {*/}
                {/*                 }}/>*/}
                {/*</div>*/}

                {!!test &&
                <div className={styles.container}>
                    <PatientTestsResultsView patientId={props.patientId} test={test!} testResults={testResults}
                                             onAdd={onAdd}
                                             onEdit={onEdit}
                                             onDelete={onDelete}/></div>
                }
            </div>
        );
}

export default PatientTestResultsTab;