import {Button} from "semantic-ui-react";
import React from "react";
import styles from "./index.module.css";
import {removeToken} from "../../utils/common";
import history from "../../history";

export const Logout = (): JSX.Element => {
    const label: string = `Подтвердите выход`;
    const confirm: string = `Выйти`;

    const onLogout = async (): Promise<void> => {
        removeToken();

        history.push("/");
    };

    return (
        <div className={styles.container}>

            <div className={styles.label}>
                {label}
            </div>

            <Button
                className={styles.button}
                size={'large'}
                color={'teal'}
                type={'submit'}
                onClick={onLogout}
            >
                {confirm}
            </Button>

        </div>
    )
}