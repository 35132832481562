import React, {ChangeEvent, MouseEventHandler, useEffect, useState} from "react";
import styles from './index.module.css';
import {
    Button,
    Dimmer,
    Form, Grid, GridColumn, Icon,
    InputOnChangeData,
    Loader,
    Modal, Segment, Tab, Table, TextArea
} from "semantic-ui-react";
import {TestResult, TestResultIndicatorValue} from "../../../test/TestResultModel";
import agent from "../../../../agent";
import {Test, TestIndicator} from "../../../test/TestModel";
import {Utils} from "../../../../helpers/Utils";

interface Props {
    fileId: string;
    testResult: TestResult;
    onClose: (success: Boolean) => void;
}

interface ParseResponse {
    text: string,
    lines: ParseLine[]
}

interface ParseLine {
    num: number,
    text: string,
    value?: ParseLineValue
}

interface ParseLineValue {
    key: string,
    value: string,
}

interface TestIndicatorLine {
    indicator: TestIndicator,
    value?: String,
    line?: ParseLine
}

const TestResultScanModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [lines, setLines] = useState<ParseLine[]>([]);
    const [text, setText] = useState<String | undefined>(props.testResult.text);

    const [indicatorLines, setIndicatorLines] = useState<TestIndicatorLine[]>();
    const [selectedLine, setSelectedLine] = useState<ParseLine | undefined>(undefined);

    useEffect(() => {
        load().then(() => {
        });
    }, []);

    function plineHasKey(pline: ParseLine, keys: string[]) {
        if (pline.value?.key) {
            return !!keys.find((key) => {
                let s = key.trim().toLowerCase();
                return (pline.value?.key.toLowerCase()?.indexOf(s) ?? -1) >= 0;
            });
        }
        return false;
    }

    function tryFindParseLines(pplines: ParseLine[], ilines: TestIndicatorLine[]) {
        let plines = [...pplines];
        let usedPlines: ParseLine[] = [];

        ilines
            .filter((x) => !x.line)
            .forEach((x) => {
                let keys: string[] = [];
                keys.push(x.indicator.code.toString());
                keys.push(...x.indicator.name.split(",").map((x) => x.trim()));
                x.line = plines
                    .filter((pline) => !usedPlines.find((x) => x == pline))
                    .find((pline) => plineHasKey(pline, keys));
                if (x.line) {
                    x.value = x.line.value?.value;
                    usedPlines.push(x.line);
                }
            });
    }

    async function load() {
        setIsLoading(true);
        let res: ParseResponse = await agent.FileProvider.parse(props.fileId);
        await setLines(res.lines);
        await setText(res.text);
        let test: Test = await agent.TestProvider.open(props.testResult.testId!.toString());
        let newIndicatorLines = test.indicators.map((x) => {
            let v = (props.testResult.values ?? []).find((v) => v.code === x.code);
            const line: TestIndicatorLine = {
                indicator: x,
                value: v?.value
            };
            return line;
        });
        tryFindParseLines(res.lines, newIndicatorLines);
        setIndicatorLines(newIndicatorLines);
        setIsLoading(false);
    }


    function buildLinesTable() {
        return <Table selectable compact sortable striped verticalAlign='middle'
        >
            <Table.Header className={styles.thead}>
                <Table.HeaderCell width={1}>№</Table.HeaderCell>
                <Table.HeaderCell width={2}>Ключ</Table.HeaderCell>
                <Table.HeaderCell width={1}>Значение</Table.HeaderCell>
                <Table.HeaderCell width={3}>Текст</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {
                    lines.map((line) => <Table.Row
                        active={selectedLine == line}
                        onClick={() => {
                            setSelectedLine(line);
                        }}>
                        <Table.Cell>
                            {line.num}
                        </Table.Cell>
                        <Table.Cell>
                            {line.value?.key}
                        </Table.Cell>
                        <Table.Cell className={selectedLine == line ? styles.td_value_sel : styles.td_value}
                                    textAlign="center">
                            <b>{line.value?.value}</b>
                        </Table.Cell>
                        <Table.Cell>
                            {line.text}
                        </Table.Cell>
                    </Table.Row>)
                }

            </Table.Body>
        </Table>
    }


    function buildTestResultValuesTable() {
        return <Table selectable compact sortable striped verticalAlign='middle'
        >
            <Table.Header>
                <Table.HeaderCell width={2}>Показатель</Table.HeaderCell>
                <Table.HeaderCell width={1}>Код</Table.HeaderCell>
                <Table.HeaderCell width={1}>Значение</Table.HeaderCell>
                <Table.HeaderCell width={1}>Ед.изм</Table.HeaderCell>
                <Table.HeaderCell width={1}>Границы</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {
                    indicatorLines?.flatMap((line) =>
                        [<Table.Row onClick={() => {
                            if (selectedLine) {
                                line.value = selectedLine.value?.value || "";
                                line.line = selectedLine;
                                setIndicatorLines(indicatorLines?.map((x) => {
                                    return x;
                                }));
                                setSelectedLine(undefined);
                            }
                        }}>
                            <Table.Cell width={"2"}>
                                {line.indicator.name}
                            </Table.Cell>
                            <Table.Cell width={"1"}>
                                {line.indicator.code}
                            </Table.Cell>
                            <Table.Cell width={"1"} className={styles.td_value}>
                                <input value={line.value?.toString()}
                                       className={styles.input_value}
                                       onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                           line.value = e.target.value;
                                           setIndicatorLines(indicatorLines?.map((x) => {
                                               return x;
                                           }));
                                       }}/>
                                {line.line?.value?.key && <p className={styles.hint}>{line.line?.value?.key}</p>}
                            </Table.Cell>
                            <Table.Cell width={"2"}>
                                {line.indicator.units}
                            </Table.Cell>
                            <Table.Cell>
                                {Utils.getRefsText(line.indicator)}
                            </Table.Cell>
                        </Table.Row>]
                    )
                }
            </Table.Body>
        </Table>
    }

    function onSave() {
        indicatorLines?.forEach((indicatorLine) => {
            let v = (props.testResult.values ?? []).find((v) => v.code === indicatorLine.indicator.code);
            if (indicatorLine.value) {
                if (!v) {
                    const newValue: TestResultIndicatorValue = {
                        code: indicatorLine.indicator.code,
                        value: indicatorLine.value,
                        num: parseFloat(indicatorLine.value.toString())
                    }
                    props.testResult.values?.push(
                        newValue
                    )
                } else if (v.value !== indicatorLine.value)
                    v.value = indicatorLine.value;
            }
        });
        props.testResult.text = text;
        props.onClose(true);
    }

    const panes = [
        {
            menuItem: 'Показатели', render: () => <Tab.Pane> <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column>
                        <div className="scrolling content">
                            {buildLinesTable()}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="scrolling content">
                            {buildTestResultValuesTable()}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Tab.Pane>
        },
        {menuItem: 'Текст', render: () => <Tab.Pane>
                <Form>
                <TextArea value={text?.toString()} rows={24} readonly />
                </Form>
            </Tab.Pane>}
    ];

    return (
        <Modal
            style={{width: "90%", height: "80%"}}
            onClose={() => { props.onClose(false); }}
            open={true}
            closeIcon>
            <Modal.Header>Распознавание анализов</Modal.Header>
            <Modal.Content>
                {
                    isLoading ?
                        <Dimmer active inverted>
                            <Loader size='medium'>Идет распознавание текста...</Loader>
                        </Dimmer> :
                        <Tab panes={panes} />
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={"Сохранить"}
                    onClick={onSave}
                    positive
                />
                <Button
                    color={'grey'}
                    onClick={()=> {props.onClose(false); }}>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>);
}


export default TestResultScanModal;